import { TC_BRIDGES_API_URL } from '@/configs';
import { HistoryItemType } from '@/containers/History/History.types';
import { FetchHistoryListPayload } from '@/interfaces/api/history';
import { camelCaseKeys } from '@trustless-computer/dapp-core';
import createAxiosInstance from './http-client';

const apiClient = createAxiosInstance({ baseURL: TC_BRIDGES_API_URL });

const API_PATH = '/api/deposit-withdraw-history';

export const transformHistory = (historyList: any[]): HistoryItemType[] => {
  return (
    historyList.map((history) => {
      const { createdAt, type } = history;
      const mapperHistory: any = {
        ...history,
        type: type ? (type as string).toLowerCase() : type,
        createdAt: createdAt ? new Date(createdAt).getTime() : createdAt,
      };
      return mapperHistory;
    }) || []
  );
};

export const fetchHistoryListAPI = async (
  payload: FetchHistoryListPayload,
): Promise<any[]> => {
  try {
    const { address, network } = payload;

    let url;

    if (network) {
      url = `${API_PATH}?address=${address}&network=${network || ''}`;
    } else {
      url = `${API_PATH}?address=${address}`;
    }
    const response = await apiClient.get(url);

    let result;
    if (Array.isArray(response)) {
      result = Object(camelCaseKeys(response || []));
    } else {
      result = [];
    }

    result = transformHistory(result);

    return result;
  } catch (error: any) {
    console.log('[fetchHistoryListAPI] error: ', error);
    throw error;
  }
};
