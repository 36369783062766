import { getReceiverTokenAddress } from '@/containers/FormBridge/FormBridge.utils';
import { getUserSelector } from '@/state/user/selector';
import { useAppDispatch } from '@state/hooks';
import { useWeb3React } from '@web3-react/core';
import { debounce } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchEstimateWithdrawData } from './actions';
import { formBridgeActions } from './reducer';
import {
  getFormBridgeInfo,
  getFromTokenListSelector,
  getToTokenSelectedSelector,
} from './selector';
import { Token } from '@/state/tokens/types';
import {
  getNetworkByNameSelector,
  isLayer2NetworkByNameSelector,
} from '@/state/network/selector';
import { FormType } from './types';

export default function FormUpdater(): null {
  const dispatch = useAppDispatch();

  const { account: metasMaskAddress } = useWeb3React();
  const { walletAddress: tcAddress } = useSelector(getUserSelector);
  const toTokenSelectedObj = useSelector(getToTokenSelectedSelector);
  const isLayer2NetworkByNameFunc = useSelector(isLayer2NetworkByNameSelector);
  const getNetworkByNameSelectorFunc = useSelector(getNetworkByNameSelector);
  const fromTokenList = useSelector(getFromTokenListSelector);

  const {
    fromTokenSelected,
    fromNetworkSelected,
    toNetworkSelected,
    formType,
    toTokenSelected,
    toNetworkObject,
    isBurnNativeToken,
    fromNetworkObject,
    isCrossLayer2,
  } = useSelector(getFormBridgeInfo);

  const tcTokenID = React.useMemo(() => {
    return getReceiverTokenAddress({
      network: toNetworkSelected,
      token: fromTokenSelected,
      formType,
    });
  }, [fromTokenSelected, toNetworkSelected, formType]);

  const debounceFetchEsimateWithdraw = useCallback(
    debounce(
      ({
        fromTokenSelected,
        tcTokenID,
        metasMaskAddress,
        toNetworkSelected,
        toNetworkObject,
        toTokenSelectedObj,
        fromNetworkSelected,
        fromNetworkObject,
        isCrossLayer2,
      }) => {
        if (
          !fromTokenSelected ||
          !fromNetworkSelected ||
          !tcTokenID ||
          !metasMaskAddress ||
          !toTokenSelectedObj ||
          !fromNetworkObject
        ) {
          console.log('[debounceFetchEsimateWithdraw] ---- INVALID - ', {
            A: !fromTokenSelected,
            B: !fromNetworkSelected,
            C: !tcTokenID,
            D: !metasMaskAddress,
            E: !toTokenSelectedObj,
            F: !fromNetworkObject,
          });
          return;
        }

        dispatch(
          fetchEstimateWithdrawData({
            tcTokenID: tcTokenID,
            tcAddress: tcAddress || metasMaskAddress || '',
            isBridgeLayer: isCrossLayer2,
            dstNetworkId: isCrossLayer2 ? toNetworkObject?.id : '',
            dstTcTokenID: (toTokenSelectedObj as Token).tcTokenID,
            network: fromNetworkSelected,
            fromChainID: fromNetworkObject.chainId,
            toChainID: toNetworkObject.chainId,
          }),
        );
      },
      500,
    ),
    [],
  );

  const clearEstimateWithdrawDataHandler = () => {
    dispatch(formBridgeActions.clearEstimateWithdrawData());
    debounceFetchEsimateWithdraw({ toNetworkSelected: undefined });
  };

  useEffect(() => {
    if (!fromTokenSelected || !tcTokenID) {
      return;
    }
    switch (formType) {
      case 'Invalid':
      case 'Deposit':
      case 'Transfer':
        break;
      case 'Withdraw':
        {
          console.log('=> LOG -- ', {
            fromTokenSelected,
            tcTokenID,
            metasMaskAddress,
            toNetworkSelected,
            toNetworkObject,
            toTokenSelectedObj,
            fromNetworkSelected,
            fromNetworkObject,
            isCrossLayer2,
          });

          // Only Native Token on Trustless chain => Not Call API Estimate
          if (fromTokenSelected?.isNativeBridge === true && !isBurnNativeToken) {
            clearEstimateWithdrawDataHandler();
            return;
          }

          debounceFetchEsimateWithdraw({
            fromTokenSelected,
            tcTokenID,
            metasMaskAddress,
            toNetworkSelected,
            toNetworkObject,
            toTokenSelectedObj,
            fromNetworkSelected,
            fromNetworkObject,
            isCrossLayer2,
          });
        }
        break;
      default:
        break;
    }
  }, [
    fromTokenSelected,
    formType,
    metasMaskAddress,
    fromNetworkSelected,
    tcAddress,
    toNetworkSelected,
    tcTokenID,
    toTokenSelected,
    toNetworkObject,
    toTokenSelectedObj,
    isBurnNativeToken,
    isCrossLayer2,
  ]);

  useEffect(() => {
    if (!metasMaskAddress) {
      dispatch(formBridgeActions.setGenerateDepositData(undefined));
    }
  }, [metasMaskAddress]);

  //Update FormType
  useEffect(() => {
    let formType: FormType = 'Invalid';
    //FromNetwork = null || toNetwork = null
    if (!fromNetworkSelected || !toNetworkSelected) {
      formType = 'Invalid';
    } else {
      const fromNetworkIsLayer2 = isLayer2NetworkByNameFunc(fromNetworkSelected);
      const toNetworkIsLayer2 = isLayer2NetworkByNameFunc(toNetworkSelected);

      // Another => L2
      if (!fromNetworkIsLayer2 && toNetworkIsLayer2) {
        formType = 'Deposit';
      }
      // L2 => Another
      else if (fromNetworkIsLayer2 && !toNetworkIsLayer2) {
        formType = 'Withdraw';
      }
      // L2 => L2 (fromNetwork !== toNetwork )
      else if (
        fromNetworkIsLayer2 &&
        toNetworkIsLayer2 &&
        fromNetworkSelected?.toLowerCase() !== toNetworkSelected?.toLowerCase()
      ) {
        formType = 'CrossLayer2';
      } else if (
        fromNetworkSelected?.toLowerCase() === toNetworkSelected?.toLowerCase()
      ) {
        // L2 => L2 (fromNetwork === toNetwork )
        formType = 'Transfer';
      } else {
        //Default
        formType = 'Invalid';
      }
    }

    console.log('LOG formType ', formType);

    dispatch(formBridgeActions.setFormType(formType));
  }, [fromNetworkSelected, toNetworkSelected, isLayer2NetworkByNameFunc]);

  //Update From Network Object
  useEffect(() => {
    if (fromNetworkSelected) {
      const fromNetworkObj = getNetworkByNameSelectorFunc(fromNetworkSelected);
      dispatch(formBridgeActions.setFromNetworkObj(fromNetworkObj));
    }
  }, [fromNetworkSelected, getNetworkByNameSelectorFunc]);

  //Update From Token Object
  useEffect(() => {
    //  fromTokenList === 1, support auto selected for User
    if (fromTokenList && fromTokenList.length === 1) {
      dispatch(formBridgeActions.setFromTokenSelected(fromTokenList[0]));
    }
  }, [fromTokenList]);

  return null;
}
