import useThrottle from '@/hooks/useThrottle';
import { FC, useCallback, useState } from 'react';

const withRotate = (Component: any) => (props: any) => {
  const [rotate, setRotate] = useState(false);

  const rotateHandler = (e: any) => {
    setRotate(true);
    props.onClick && props.onClick(e);
    setTimeout(() => {
      setRotate(false);
    }, 1000);
  };

  const onAnimationRotate = useCallback(useThrottle(rotateHandler), []);

  return (
    <Component
      {...props}
      className={`${rotate ? 'animation-rotate' : ''}`}
      onClick={onAnimationRotate}
    ></Component>
  );
};

export default withRotate;
