import { Input, InputProps } from '@chakra-ui/react';

const AppInput = (props: InputProps) => {
  return (
    <Input
      minHeight={'55px'}
      overflow={'hidden'}
      textOverflow={'ellipsis'}
      px={'12px'}
      py={'16px'}
      fontSize={'18px'}
      fontWeight={600}
      color={'#fff'}
      bgColor={'#000'}
      borderRadius={'8px'}
      border={'1px solid #494b51'}
      type="text"
      step={'any'}
      autoComplete="off"
      spellCheck={false}
      _hover={{
        opacity: 0.7,
        caretColor: '#fff',
        borderColor: '#fff',
      }}
      _disabled={{
        opacity: 1,
        cursor: 'not-allowed',
      }}
      {...props}
    ></Input>
  );
};

export default AppInput;
