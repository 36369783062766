import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { fetchNetworks } from '@/state/network/actions';
import { getNetworkInforSelector } from '@/state/network/selector';
import { fetchToken } from '@/state/tokens/actions';
import { getTokenInfoSelector } from '@/state/tokens/selector';
import React, { FC, useEffect } from 'react';

const withFetcher = (WrappedComponent: FC) => (props: any) => {
  WrappedComponent.displayName = 'withFetcher';

  const dispatch = useAppDispatch();
  const { error: fetchNetworkError, isFetched: isNetworkFetched } = useAppSelector(
    getNetworkInforSelector,
  );

  const { error: fetchTokenError, isFetched: isTokenFetched } =
    useAppSelector(getTokenInfoSelector);

  useEffect(() => {
    const fetchAPIs = async () => {
      await dispatch(fetchNetworks());
      await dispatch(fetchToken());
    };
    fetchAPIs();
  }, []);

  if (fetchNetworkError) return <p>Fetch Network something went wrong! </p>;
  if (fetchTokenError) return <p>Fetch Tokens something went wrong! </p>;

  if (!isNetworkFetched || !isTokenFetched) return <></>;

  return <WrappedComponent {...props} />;
};

export default withFetcher;
