import { useAppDispatch, useAppSelector } from '@/state/hooks';
import useNetworkHook from '@/state/network/hook';
import { networkActionCreators } from '@/state/network/reducer';
import {
  allNetworkStrListSelector,
  getAllNetworkListSelector,
  getCurrentL2NetworkSelector,
} from '@/state/network/selector';
import { INetwork } from '@/state/network/types';
import { getTokenListSelector } from '@/state/tokens/selector';
import { orderBy, uniq } from 'lodash';
import { FC, useEffect, useState } from 'react';

const filterNetworkNameAvailableList = (WrappedComponent: FC) => (props: any) => {
  WrappedComponent.displayName = 'filterNetworkNameAvailableList';

  const dispatch = useAppDispatch();

  const { getNetworkByNameFunc } = useNetworkHook();

  const allTokenList = useAppSelector(getTokenListSelector) || [];
  const currentNetworkLayer2 = useAppSelector(getCurrentL2NetworkSelector);
  const allNetworkStrList = useAppSelector(allNetworkStrListSelector);
  const allNetworkList = useAppSelector(getAllNetworkListSelector);

  const [isDone, setDone] = useState(false);

  // console.log(
  //   '🚀 ~ filterNetworkNameAvailableList ~ allNetworkStrList:',
  //   allNetworkStrList,
  //   currentNetworkLayer2,
  //   allNetworkStrList,
  //   allTokenList,
  // );

  useEffect(() => {
    let networkNameAvailableList: string[] = [];
    let networkListByUsing: INetwork[] = [];

    if (currentNetworkLayer2) {
      networkNameAvailableList.push(currentNetworkLayer2);

      allTokenList.map((token) => {
        let tokenAddressMap = token.tokenAddress;
        let networkListByCurrentToken = Object.keys(tokenAddressMap) || [];

        let isExist = networkListByCurrentToken.find(
          (networkName) =>
            networkName.toLowerCase() === currentNetworkLayer2.toLowerCase(),
        );
        if (isExist) {
          networkNameAvailableList.push(token.network);
        }
      });

      networkNameAvailableList = uniq(networkNameAvailableList);

      //Sort network (BTC => ETH => Layer2s...)
      networkNameAvailableList = orderBy(
        networkNameAvailableList,
        (networkName) => [
          networkName.toUpperCase() === 'BITCOIN',
          networkName.toUpperCase() === 'ETHEREUM',
        ],
        ['desc'],
      );

      networkNameAvailableList.map((networkName) => {
        const networkObj = getNetworkByNameFunc(networkName);
        if (networkObj) {
          networkListByUsing.push(networkObj);
        }
      });
    } else {
      //Get All Network
      networkNameAvailableList = [...allNetworkStrList];
      networkListByUsing = [...allNetworkList];
    }

    console.log('🚀 ~ useEffect ~ DEBUG:', {
      networkNameAvailableList,
      networkListByUsing,
    });

    dispatch(networkActionCreators.setNetworkListUsing(networkListByUsing));
    dispatch(
      networkActionCreators.setNetworkNameAvailableList(networkNameAvailableList),
    );
    setDone(true);
  }, []);

  if (!isDone) return <></>;
  return <WrappedComponent {...props} />;
};

export default filterNetworkNameAvailableList;
