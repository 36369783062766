import { Text, TextProps } from '@chakra-ui/react';

interface ErrorProps extends TextProps {
  message?: string;
}

const Error = (props: ErrorProps) => {
  return (
    <Text color={'#ff5747'} mt="5px" {...props}>
      {`${props.message || ''}`}
    </Text>
  );
};

export default Error;
