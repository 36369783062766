import NetworkView from '@/componentsV2/NetworkView';
import { getNetworkByNameSelector } from '@/state/network/selector';
import { NetworkName } from '@/state/network/types';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formBridgeActions } from '../state/reducer';
import {
  getFormBridgeInfo,
  getToNetworkListSelector,
  toNetworkFactorySelectorV2,
} from '../state/selector';

const ToNetworkSelect = () => {
  const dispatch = useDispatch();
  const { toNetworkSelected, fromTokenSelected, toNetworkObject } =
    useSelector(getFormBridgeInfo);
  const getNetworkObjectByName = useSelector(getNetworkByNameSelector);
  const toNetworkList = useSelector(getToNetworkListSelector);
  const { title, disbaled } = useSelector(toNetworkFactorySelectorV2);

  useEffect(() => {
    if (fromTokenSelected) {
      if (toNetworkList && toNetworkList.length === 1) {
        dispatch(formBridgeActions.setToNetwork(toNetworkList[0]));
      }
    }
  }, [toNetworkList, fromTokenSelected]);

  const itemOnClick = (network: NetworkName) => {
    if (network !== toNetworkSelected) {
      const networkObj = getNetworkObjectByName(network);
      dispatch(formBridgeActions.setToNetwork(network));
      dispatch(formBridgeActions.setToNetworkObj(networkObj));
    }
  };

  return (
    <Menu>
      <MenuButton
        minH={'50px'}
        disabled={disbaled}
        my={'4px'}
        px={'8px'}
        h={'100%'}
        borderRadius={'4px'}
        bgColor={'#1B1E26'}
        opacity={disbaled ? 0.5 : 1}
        _hover={{
          bg: disbaled ? '' : '#BFBFBFAA',
          cursor: disbaled ? 'not-allowed' : 'pointer',
        }}
      >
        <Flex flexDir={'row'} align={'center'} justifyContent={'space-between'}>
          {!toNetworkObject ? (
            <Text>{title}</Text>
          ) : (
            <NetworkView
              name={toNetworkObject.networkTitle || ''}
              iconUrl={toNetworkObject.networkLogo}
            />
          )}
          <ChevronDownIcon />
        </Flex>
      </MenuButton>
      <MenuList minW={'300px'} maxH={'400px'} overflowY="auto">
        {toNetworkList.map((item, index) => {
          const networkObj = getNetworkObjectByName(item);
          return (
            <MenuItem
              key={`${item}-${index}`}
              onClick={() => itemOnClick(networkObj?.networkName || '')}
              minH={'50px'}
            >
              <NetworkView
                name={networkObj?.networkTitle || ''}
                iconUrl={networkObj?.networkLogo}
              />
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

export default ToNetworkSelect;
