import TokenView from '@/componentsV2/TokenView';
import { useAppSelector } from '@/state/hooks';
import { Token } from '@/state/tokens/types';
import { getIconUrl } from '@/utils/token';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { formBridgeActions } from '../state/reducer';
import { getFormBridgeInfo, getFromTokenListSelector } from '../state/selector';

const FromTokenSelect = () => {
  const dispatch = useDispatch();
  const { fromTokenSelected, fromNetworkSelected } =
    useAppSelector(getFormBridgeInfo);
  const fromTokenList = useAppSelector(getFromTokenListSelector);
  const tokenOnClick = (token: Token) => {
    if (fromTokenSelected?.id !== token.id) {
      dispatch(formBridgeActions.setFromTokenSelected(token));
    }
  };

  const isEmptyToken = !fromTokenSelected;
  const disabled = !fromNetworkSelected;

  return (
    <Menu>
      <MenuButton
        minH={'50px'}
        my={'4px'}
        px={'8px'}
        h={'100%'}
        borderRadius={'4px'}
        bgColor={'#1B1E26'}
        disabled={disabled}
        opacity={disabled ? 0.5 : 1}
        _hover={{
          bg: disabled ? '' : '#BFBFBFAA',
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
      >
        <Flex flexDir={'row'} align={'center'} justifyContent={'space-between'}>
          {isEmptyToken ? (
            <Text>{'Select token'}</Text>
          ) : (
            <TokenView
              name={fromTokenSelected?.symbol || ''}
              iconUrl={getIconUrl({
                token: fromTokenSelected,
              })}
            />
          )}
          <ChevronDownIcon />
        </Flex>
      </MenuButton>
      <MenuList minW={'300px'} maxH={'400px'} overflowY="auto">
        {fromTokenList &&
          fromTokenList.map((item, index) => {
            return (
              <MenuItem
                key={`${item.tcTokenID}-${index}`}
                onClick={() => tokenOnClick(item)}
                minH={'50px'}
                as="li"
              >
                <TokenView
                  name={item.symbol || ''}
                  iconUrl={getIconUrl({
                    token: item,
                  })}
                />
              </MenuItem>
            );
          })}
      </MenuList>
    </Menu>
  );
};

export default FromTokenSelect;
