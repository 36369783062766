import { RootState } from '@/state';
import { createSelector } from 'reselect';
import { NetworkName } from './types';

export const reducerSelector = (state: RootState) => state.networkReducer;

export const getAllNetworkListSelector = createSelector(
  reducerSelector,
  (reducer) => reducer.allNetworkList || [],
);

export const allNetworkStrListSelector = createSelector(
  getAllNetworkListSelector,
  (allNetworkList) => allNetworkList.map((item) => item.networkName),
);

export const getNetworkListSelector = createSelector(
  reducerSelector,
  (reducer) => reducer.networkList || [],
);

export const isFetchedNetworkSelector = createSelector(
  reducerSelector,
  (reducer) => reducer.isFetched,
);

export const getNetworkNameListSelector = createSelector(
  getNetworkListSelector,
  (networkList) => {
    return networkList
      .map((network) => network.networkName)
      .filter((name) => !!name);
  },
);

// Result
//  chainID
//  Otherwise:  -1 network not found => return chainID = -1
export const getChainIDByNetworkNameSelector = createSelector(
  getNetworkListSelector,
  (networkList) => (networkName: NetworkName) => {
    const networkFinded = networkList.find(
      (network) => networkName === network.networkName,
    );
    return networkFinded ? networkFinded.chainId : -1;
  },
);

// Result
//  networkFinded
//  undefined (not found)
export const getNetworkByNameSelector = createSelector(
  getAllNetworkListSelector,
  (allNetworkList) => (networkName: NetworkName | undefined) => {
    const networkFinded = allNetworkList.find(
      (network) => networkName === network.networkName,
    );
    return networkFinded;
  },
);

export const getNetworkInforSelector = createSelector(
  [reducerSelector, getNetworkNameListSelector],
  (state, networkList) => {
    const result = {
      ...state,
      networkList,
    };

    return result;
  },
);

export const getCurrentL2NetworkSelector = createSelector(
  reducerSelector,
  (reducer) => reducer.currentL2Network,
);

export const getNetworkNameAvailableListSelector = createSelector(
  reducerSelector,
  (reducer) => reducer.networkNameAvailableList || [],
);

export const getFromNetworkListSelector = createSelector(
  [
    getNetworkNameAvailableListSelector,
    getNetworkByNameSelector,
    getCurrentL2NetworkSelector,
  ],
  (networkNameAvailableList, getNetworkByNameSelector, currentL2Network) => {
    return networkNameAvailableList.filter((networkName) => {
      const networkObj = getNetworkByNameSelector(networkName);

      //OK currentL2Network === networkName ==> OK
      if (networkObj?.networkName.toLowerCase() === currentL2Network) {
        return true;
      } else {
        //remove network IS NOT Layer2 (Un-Support L2 <-> L2)
        return !networkObj?.isL2aas;
      }
    });
  },
);

export const isLayer2NetworkByNameSelector = createSelector(
  [reducerSelector, getNetworkByNameSelector],
  (reducer, getNetworkByNameSelector) => (networkName: string) => {
    const networkObject = getNetworkByNameSelector(networkName);
    return networkObject?.isL2aas;
  },
);
