import { Flex, Text } from '@chakra-ui/react';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { getEstimateWithdrawSelector, getFormBridgeInfo } from '../state/selector';

const WithdrawFeeEstimate = () => {
  const { isLoading, data } = useSelector(getEstimateWithdrawSelector);
  const { fromTokenSelected, isTransferForm } = useSelector(getFormBridgeInfo);
  const withdrawFeeHRFormatStr = data?.withdrawFeeHRFormatStr;
  const isBlockToken = data?.isBlockToken;

  if (isLoading) return <Skeleton count={1} />;
  if (!withdrawFeeHRFormatStr || !fromTokenSelected) return null;
  if (isBlockToken) return null;

  if (isTransferForm) return null;

  const tokenSymbol = fromTokenSelected?.symbol?.toUpperCase();

  return (
    <Flex align={'center'} justify={'space-between'}>
      <Text>{'Transfer fee (est.)'}</Text>
      <Text fontWeight={600}>{`${withdrawFeeHRFormatStr} ${tokenSymbol}`}</Text>
    </Flex>
  );
};

export default memo(WithdrawFeeEstimate);
