import QrCode from '@/components/QrCode';
import EstimateTimeBox from '@/containers/FormBridge/components/EstimateTimeBox';
import Space from '@components/Space';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import AddressBox from './AddressBox';
import DepositFeeEstimate from './DepositFeeEstimate';
import DescriptionQrCode from './DescriptionQrCode';
import MinimumDepositAmount from './MinimumDepositAmount';

// import YourBalanceBTC from './YourBalanceBTC';
import { QrCodeAndAddressBox } from './styled';

import { useSelector } from 'react-redux';
import {
  getFormBridgeInfo,
  getGenerateDepositSelector,
} from '../FormBridge/state/selector';
import { Flex } from '@chakra-ui/react';

export const ModalStyled = styled(Modal)`
  &.modal {
    --bs-modal-color: rgb(00, 00, 00);
  }

  .modal-content {
    padding: 15px;
    background: #1b1e26;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 16px;
  }

  .modal-header {
    border-bottom: 0.5px solid #353945;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  min-height: 200px;
  width: 100%;

  /* background-color: rgb(48, 48, 48); */
  /* background-color: rgb(26, 26, 26); */
  border-radius: 10px;
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: left;
  text-transform: capitalize;

  color: #ffffff;

  opacity: 0.7;

  margin-bottom: 10px;
`;

type Props = {
  isShow: boolean;
  onHide?: any;
  flowWithdraw?: boolean;
};

function DepositInfoModal(props: Props) {
  const { isShow, onHide, flowWithdraw = false } = props;

  const { isLoading, data } = useSelector(getGenerateDepositSelector);
  const { fromTokenSelected } = useSelector(getFormBridgeInfo);
  const depositAddress = data?.depositAddress;

  const isBlur = isLoading || !data || !depositAddress || depositAddress === '';

  const title = `Transfer ${fromTokenSelected?.symbol?.toUpperCase()}`;

  if (!depositAddress || depositAddress.length < 1) return null;

  return (
    <ModalStyled centered show={isShow} keyboard={false} onHide={onHide}>
      <Modal.Header closeButton color="white" closeVariant="white">
        <Modal.Title style={{ color: 'white' }}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <DescriptionQrCode flowWithdraw={flowWithdraw} />

          <Space.Vertical size={10} />

          <QrCodeAndAddressBox>
            <QrCode
              qrCodeProps={{
                value: depositAddress || '',
                size: 200,
              }}
              isBlur={isBlur}
            />
          </QrCodeAndAddressBox>
          <AddressBox />
          <Space.Vertical size={25} />

          {!flowWithdraw && (
            <Flex flexDir={'column'} gap={'6px'}>
              {/* {!isNativeToken && <WarningNeedDepositBTCMore />} */}
              {/* {isBrc20 && <YourBalanceBTC />} */}
              <Space.Vertical size={5} />
              <DepositFeeEstimate />
              {<MinimumDepositAmount />}
              {/* <MinimumDepositAmount /> */}
              <EstimateTimeBox />
              <Space.Vertical size={15} />

              {/* {!isBlur && <WarningMessage />} */}
            </Flex>
          )}
        </Container>
      </Modal.Body>
    </ModalStyled>
  );
}

export default DepositInfoModal;
