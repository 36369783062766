import ERC20Json from '@/abis/erc20_array.json';
import { ZERO_ADDRESS } from '@/constants/address';
import { TransactionEventType } from '@/enums/transaction';
import { ContractOperationHook, DAppType } from '@/interfaces/contract-operation';
import { getContract } from '@/utils';
import { useWeb3React } from '@web3-react/core';
import { useCallback } from 'react';

export interface ITransferParams {
  tokenAddress: string;
  amount: string;
  receiver: string;
}

const useTransfer: ContractOperationHook<ITransferParams, any | undefined> = () => {
  const { account, provider } = useWeb3React();
  const call = useCallback(
    async (params: ITransferParams): Promise<any | undefined> => {
      if (account && provider) {
        const { amount, receiver, tokenAddress } = params;

        // [1] Transfer Native Token
        if (tokenAddress === ZERO_ADDRESS) {
          const tx = await provider.getSigner().sendTransaction({
            from: account,
            to: receiver,
            value: amount.toString(),
          });

          console.log('Transfer Native Token => tx ', tx);

          if (tx) {
            return {
              hash: Object(tx).hash,
            };
          }
        } else {
          // [2] Transfer Other Token
          const contract = getContract(
            tokenAddress,
            ERC20Json,
            provider,
            account,
          ).connect(provider.getSigner());

          const tx = await contract['transfer(address,uint256)'](receiver, amount);

          if (tx) {
            return {
              hash: Object(tx).hash,
            };
          }
        }
      }
      return undefined;
    },
    [account, provider],
  );

  return {
    call: call,
    dAppType: DAppType.Contract,
    transactionType: TransactionEventType.DEPLOY,
  };
};

export default useTransfer;
