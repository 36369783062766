import CopyIcon2 from '@/components/IconSVG/CopyIcon2';
import { Image } from '@/components/Image';
import QrCode from '@/components/QrCode';
import Text from '@/components/Text';
import ToolTip from '@/components/Tooltip';
import { ellipsisCenter } from '@/utils/address';
import { formatAmount } from '@/utils/format';
import { formatDateTime } from '@/utils/time';
import { getIconUrlFromHistory } from '@/utils/token';
import { getNetworkListSelector } from '@state/network/selector';
import copy from 'copy-to-clipboard';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Info } from 'react-feather';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import {
  LOCAL_PENDING_STATUS,
  WAITING_INSCRIBED_STATUS,
} from '../History.constants';
import { HistoryItemType, HistoryTypes } from '../History.types';
import {
  ellipsisByTx,
  getDirectionStr,
  getExplorerLink,
  getToolTipMessage,
  getTxStatusDecorator,
  isSuccessfulTx,
  isTxInsufficientFee,
  isTxInvalid,
  isTxWatingInscribed,
} from '../History.utils';
import {
  Column,
  Container,
  Link,
  Row,
  Title,
  WrapCopyIcon,
  WrapTitle,
} from './HistoryItem.styled';

type Props = {
  item: HistoryItemType;
  index: number;
  addressInput: string;
};

const HistoryItem = (props: Props) => {
  const {
    type = 'unknown',
    tcAddress,
    withdrawWallet,
    createdAt = '',
    amountTc,
    txProcessWithdraw,
    txTcProcessDeposit,
    txBurnedWithdraw,
    txReceivedDeposit,
    tcTokenInfo,
    amountBurnedWithdraw,
    amountReceivedDeposit,
    status = 0,
    statusStr,
    statusType,
    fee = '0.0',
    note,
    symbol,
    depositWallet,
    fromToNetworkInfo,
    fromNetworkName,
    toNetworkName,
  } = props.item;

  const typeLowerCase = type.toLowerCase() as HistoryTypes;
  const networkList = useSelector(getNetworkListSelector);

  const typeText = React.useMemo(() => {
    switch (typeLowerCase) {
      case 'deposit':
      case 'deposit l2':
      case 'l1 => l2':
      case 'cross layer':
        return 'DEPOSIT';

      case 'withdraw':
      case 'withdraw l2':
      case 'l2 => l1':
        return 'WITHDRAW';
      default:
        return 'UNKNOW';
    }
  }, [typeLowerCase]);

  const formatSymbol = React.useMemo(() => {
    if (status === LOCAL_PENDING_STATUS || status === WAITING_INSCRIBED_STATUS) {
      return symbol?.toUpperCase() || '--';
    } else {
      const tokenSymbol = tcTokenInfo?.symbol?.toUpperCase();

      switch (typeLowerCase) {
        case 'withdraw':
        case 'withdraw l2':
        case 'l1 => l2':
        case 'l2 => l1':
          return tokenSymbol ? `${tokenSymbol}` : '--';
        default:
          return tokenSymbol || '--';
      }
    }
  }, [tcTokenInfo, symbol, status]);

  const renderStatus = () => {
    const decorator = getTxStatusDecorator({
      statusStr,
      statusType,
      statusCode: status,
    });
    if (isTxWatingInscribed(status)) {
      return (
        <>
          <button
            className="button-inscribe"
            onClick={() => window.open(`trustlesswallet.io/?tab=transactions`)}
          >
            Process
          </button>
        </>
      );
    } else if (isTxInvalid(status) || isTxInsufficientFee(status)) {
      const depositAddress = isTxInsufficientFee(status) ? depositWallet : undefined;
      return (
        <Row style={{ gap: 6 }}>
          <Title style={{ color: decorator.color, fontWeight: 800 }}>
            {statusStr}
          </Title>
          {!!note && (
            <ToolTip
              unwrapElement={
                <Info
                  style={{
                    color: 'white',
                    width: 16,
                    height: 16,
                    cursor: 'pointer',
                  }}
                />
              }
              width={300}
            >
              <div style={{ fontSize: 14, padding: 8 }}>
                {note}
                {!!depositAddress && (
                  <QrCode
                    qrCodeProps={{
                      value: depositAddress || 'UNKNOWN',
                      size: 100,
                    }}
                    isBlur={!depositAddress}
                  />
                )}
                {!!depositAddress && (
                  <Row style={{ marginTop: 4 }}>
                    <Text>
                      {ellipsisCenter({
                        limit: 4,
                        str: depositAddress,
                      })}
                    </Text>
                    {renderBtnCopyAddress(depositAddress)}
                  </Row>
                )}
              </div>
            </ToolTip>
          )}
        </Row>
      );
    } else {
      if (isSuccessfulTx(status)) {
        return (
          <Title style={{ color: decorator.color, fontWeight: 800 }}>
            {statusStr}
          </Title>
        );
      } else {
        const toolTipMessage = getToolTipMessage(status);
        return (
          <div>
            <Row style={{ gap: 6 }}>
              <Title
                style={{
                  color: decorator.color,
                  fontWeight: 800,
                  fontStyle: status === LOCAL_PENDING_STATUS ? 'italic' : '',
                }}
              >
                {statusStr}
              </Title>
              {!!toolTipMessage && (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip">
                      <Text>{toolTipMessage}</Text>
                    </Tooltip>
                  }
                >
                  <Info color={'white'} size={16} className="info-icon" />
                </OverlayTrigger>
              )}
            </Row>
          </div>
        );
      }
    }
  };

  const renderBtnCopyAddress = (address: string) => {
    return (
      <WrapCopyIcon>
        <CopyIcon2
          scale={0}
          onClick={() => {
            if (address) {
              toast.success('Copied');
              copy(address);
            }
          }}
        />
      </WrapCopyIcon>
    );
  };

  const renderRowTxByData = ({
    title,
    tx,
    explorer,
  }: {
    title: string;
    tx?: string;
    explorer?: string;
  }) => {
    return (
      <Row>
        <Title>{title}</Title>
        {tx && explorer ? (
          <>
            <Link target="_blank" href={`${explorer}/${tx}`}>
              {ellipsisByTx({ tx })}
            </Link>
            {renderBtnCopyAddress(tx)}
          </>
        ) : (
          <p>{'--'}</p>
        )}
      </Row>
    );
  };

  const renderDirection = () => {
    return getDirectionStr({ fromNetworkName, toNetworkName, networkList });
  };

  const renderTransactionContentColumnNEW = () => {
    let txn = txReceivedDeposit || txBurnedWithdraw;
    let processTxn = txTcProcessDeposit || txProcessWithdraw;
    return (
      <>
        {renderRowTxByData({
          title: 'Txn:',
          tx: txn,
          explorer: getExplorerLink({ networkName: fromNetworkName, networkList }),
        })}
        {renderRowTxByData({
          title: 'Process Txn:',
          tx: processTxn,
          explorer: getExplorerLink({ networkName: toNetworkName, networkList }),
        })}
      </>
    );
  };

  const renderAmountColumn = () => {
    if (typeText === 'DEPOSIT') {
      if (amountTc) {
        return <Title>{formatAmount(amountTc, true, 18)}</Title>;
      } else if (amountReceivedDeposit) {
        return (
          <Title>
            {formatAmount(amountReceivedDeposit, true, tcTokenInfo?.decimals)}
          </Title>
        );
      }
      return '--';
    }
    if (typeText === 'WITHDRAW') {
      if (amountTc) {
        return <Title>{formatAmount(amountTc, true, 18)}</Title>;
      } else if (amountBurnedWithdraw) {
        return <Title>{formatAmount(amountBurnedWithdraw, true, 18)}</Title>;
      }
      return '--';
    }
  };

  const renderReceivingAddress = () => {
    let receivingAddress;

    if (typeText === 'DEPOSIT') {
      receivingAddress = tcAddress;
    } else if (typeText === 'WITHDRAW') {
      receivingAddress = withdrawWallet;
    } else {
      receivingAddress = tcAddress;
    }
    return (
      <Row>
        <Title
          style={{
            textAlign: 'left',
            marginRight: 10,
          }}
        >
          {'Receiving Address: '}
        </Title>
        {receivingAddress ? (
          <>
            <Text
              style={{
                color: '#b1e3ff',
                fontWeight: 'bold',
              }}
            >
              {ellipsisCenter({
                limit: 5,
                str: receivingAddress,
              })}
            </Text>
            {renderBtnCopyAddress(receivingAddress)}
          </>
        ) : (
          <p>{'--'}</p>
        )}
      </Row>
    );
  };

  return (
    <Container>
      <WrapTitle
        style={{
          maxWidth: '130px',
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <Title
          style={{
            textTransform: 'capitalize',
            lineBreak: 'auto',
            overflow: 'unset',
            wordBreak: 'break-word',
            textAlign: 'inherit',
          }}
        >
          {/* BE handle this field */}
          {fromToNetworkInfo}
          {/* {renderDirection()} */}
        </Title>
      </WrapTitle>
      <WrapTitle style={{ flex: 2, maxWidth: 290 }}>
        {/* <Column>{renderTransactionContentColumn()}</Column> */}
        <Column>
          {renderTransactionContentColumnNEW()}
          {renderReceivingAddress()}
        </Column>
      </WrapTitle>
      <WrapTitle style={{ flex: 0.8, maxWidth: 100 }}>
        {renderAmountColumn()}
      </WrapTitle>
      <WrapTitle className="fee-item" style={{ flex: 0.5, maxWidth: 120 }}>
        <Title>{formatAmount(fee, true)}</Title>
      </WrapTitle>
      <WrapTitle className="status-item" style={{ flex: 0.5, maxWidth: 80 }}>
        <Row style={{ gap: 6 }}>
          <Image
            iconUrl={
              tcTokenInfo?.logo ||
              getIconUrlFromHistory({
                symbol: formatSymbol,
              })
            }
            size={28}
            alt={`${formatSymbol}`}
          />
          <Title style={{ textTransform: 'uppercase' }}>{formatSymbol}</Title>
        </Row>
      </WrapTitle>
      <WrapTitle
        style={{
          flex: 0.8,
          maxWidth: 100,
          justifyContent: 'center',
        }}
      >
        {renderStatus()}
      </WrapTitle>
      <WrapTitle className="time-item" style={{ flex: 0.8, maxWidth: 100 }}>
        <Row>
          <Title>
            {formatDateTime({
              dateTime: new Date(createdAt).getTime(),
            }).toLocaleString()}
          </Title>
        </Row>
      </WrapTitle>
    </Container>
  );
};

export default HistoryItem;
