import ERC20ABIJson from '@/abis/erc20.json';
import { getContract } from '@/utils';
import { useWeb3React } from '@web3-react/core';

const useGetBalance = () => {
  const { account, provider } = useWeb3React();

  const isValid = !!account && !!provider;

  const getNativeTokenBalance = async () => {
    try {
      if (isValid) {
        return (await provider.getBalance(account)).toString();
      } else {
        return '0';
      }
    } catch (e) {
      throw e;
    }
  };

  const getNormalTokenBalance = async ({
    tokenAddress,
  }: {
    tokenAddress: string;
  }) => {
    try {
      if (isValid && tokenAddress) {
        const contract = getContract(
          tokenAddress,
          ERC20ABIJson.abi,
          provider,
          account,
        );
        return (await contract.connect(provider).balanceOf(account)).toString();
      } else {
        return '0';
      }
    } catch (e) {
      throw e;
    }
  };

  return {
    getNativeTokenBalance,
    getNormalTokenBalance,
  };
};

export default useGetBalance;
