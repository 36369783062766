import BigNumber from 'bignumber.js';
import { isEmpty } from 'lodash';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import {
  getFormBridgeInfo,
  getGenerateDepositSelector,
} from '../FormBridge/state/selector';
import { Flex, Text } from '@chakra-ui/react';

const DepositFeeEstimate = () => {
  const { isLoading, data } = useSelector(getGenerateDepositSelector);
  const { fromTokenSelected } = useSelector(getFormBridgeInfo);

  if (isLoading) return <Skeleton count={1} />;
  if (!data) return null;

  const { depositFeeHRFormatStr } = data;

  if (
    isEmpty(depositFeeHRFormatStr) ||
    new BigNumber(depositFeeHRFormatStr || '0').isZero()
  )
    return null;

  const symbol = fromTokenSelected?.symbol?.toUpperCase();

  return (
    <Flex align={'center'} justify={'space-between'}>
      <Text>{'Transfer fee (est.)'}</Text>
      <Text fontWeight={600}>{`${depositFeeHRFormatStr} ${symbol}`}</Text>
    </Flex>
  );
};

export default memo(DepositFeeEstimate);
