import SwapIcon from '@/components/IconSVG/SwapIcon';

import FromNetworkSelect from './components/FromNetworkSelect';
import FromTokenSelect from './components/FromTokenSelect';

import ToNetworkSelect from './components/ToNetworkSelect';
import ToTokenSelected from './components/ToTokenSelected';

import { Flex, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FormBridgeType } from './FormBridge.types';
import withRotate from '@/hocs/withRotate';

const WrapperArrowDownBtn = (props?: any) => {
  return (
    <Flex
      align={'center'}
      alignSelf={'center'}
      justify={'center'}
      display={'flex'}
      _hover={{
        cursor: 'pointer',
        opacity: 0.7,
      }}
      className={props.className}
      onClick={props.onClick}
    >
      {props?.children}
    </Flex>
  );
};

const WrapperArrowDownCircle = withRotate(WrapperArrowDownBtn);

type SelectViewProp = {
  title: string;
  children?: ReactNode;
  leftView?: ReactNode;
  rightView?: ReactNode;
};

const SelectView = (props: SelectViewProp) => {
  const { title, leftView, rightView, children } = props;
  return (
    <Flex flexDir={'column'} gap={'10px'}>
      <Text fontWeight={600}>{title}</Text>
      <Flex
        flexDir={'row'}
        justify={'space-between'}
        bgColor={'#000'}
        p={'1px'}
        minH={'120px'}
        borderRadius={'4px'}
        border={'1px solid #494b51'}
      >
        <Flex flex={1} flexDir={'column'} gap={'4px'} p={'10px'}>
          <Text fontWeight={600}>{'Network'}</Text>
          {leftView}
        </Flex>
        <Flex h={'auto'} w={'1px'} bgColor={'#494b51'}></Flex>
        <Flex flex={1} flexDir={'column'} gap={'4px'} p={'10px'}>
          <Text fontWeight={600}>{'Token'}</Text>
          {rightView}
        </Flex>
      </Flex>
    </Flex>
  );
};

const GroupSelect = (props: FormBridgeType) => {
  const { swapHandler } = props;
  return (
    <Flex flexDir={'column'} gap={'10px'}>
      <SelectView
        title="From"
        leftView={<FromNetworkSelect />}
        rightView={<FromTokenSelect />}
      />

      <Flex h={'10px'} />

      <WrapperArrowDownCircle>
        <SwapIcon onClick={swapHandler} />
      </WrapperArrowDownCircle>

      <SelectView
        title="To"
        leftView={<ToNetworkSelect />}
        rightView={<ToTokenSelected />}
      />
    </Flex>
  );
};

export default GroupSelect;
