import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { FC, useEffect, useState } from 'react';
import { formBridgeActions } from '@/containers/FormBridge/state/reducer';
import { getCurrentL2NetworkSelector } from '@/state/network/selector';
import useNetworkHook from '@/state/network/hook';

const withDefaultFromNetwork = (WrappedComponent: FC) => (props: any) => {
  WrappedComponent.displayName = 'withDefaultFromNetwork';

  const currentNetworkLayer2 = useAppSelector(getCurrentL2NetworkSelector);

  const { getNetworkByNameFunc } = useNetworkHook();
  const dispatch = useAppDispatch();

  const [isDone, setDone] = useState(false);

  useEffect(() => {
    const fromNetworkStr = currentNetworkLayer2 || 'bitcoin';
    const fromNetworkObj = getNetworkByNameFunc(fromNetworkStr);

    dispatch(formBridgeActions.setFromNetwork(fromNetworkStr));
    dispatch(formBridgeActions.setFromNetworkObj(fromNetworkObj));

    // dispatch(formBridgeActions.setFromNetwork(undefined));
    // dispatch(formBridgeActions.setFromNetworkObj(undefined));
    setDone(true);
  }, []);

  if (!isDone) return <></>;
  return <WrappedComponent {...props} />;
};

export default withDefaultFromNetwork;
