import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { FormInitialValues } from './FormBridge.constants';
import enhance from './FormBridge.enhance';
import { FormBridgeType } from './FormBridge.types';
import { getFormBridgeInfo, getGenerateDepositSelector } from './state/selector';

import AppButton from '@/componentsV2/Button';
import withWeb3Connected from '@/hocs/withWeb3Connected';
import { Flex } from '@chakra-ui/react';
import DepositInput from './FormBridge.DepositInput';
import WithdrawInput from './FormBridge.WithdrawInput';
import GroupSelect from './FormBridge.groupSelect';
import withSekeleton from './FormBridge.withSekeleton';

const AppButtonWithMetamask = withWeb3Connected(AppButton);

const FormBridge = (props: FormBridgeType) => {
  const {
    submitHandler,
    switchChainHandler,
    swapHandler,
    generateDepositDataHandler,
    maxHandler,
  } = props;

  const { isLoading } = useSelector(getGenerateDepositSelector);

  const {
    submitBtnTitle,
    disableSubmitBtn,
    isSwitchNetwork,
    isDepositForm,
    isWithdrawForm,
    isFormTypeInvalid,
    isTransferForm,
  } = useSelector(getFormBridgeInfo);

  const renderDepositFieldsInfor = (handleSubmit: any) => {
    return (
      <Flex flexDir={'column'} gap={'20px'}>
        <DepositInput />
        {isFormTypeInvalid ? null : (
          <AppButton
            title={`${isLoading ? 'Generating...' : 'Deposit'}`}
            isLoading={isLoading}
            disabled={isFormTypeInvalid}
            onClick={() => handleSubmit()}
          />
        )}
      </Flex>
    );
  };

  const renderWithdrawFieldsInfor = (handleSubmit: any) => {
    return (
      <Flex flexDir={'column'}>
        <WithdrawInput
          switchChainHandler={switchChainHandler}
          maxHandler={maxHandler}
        />
        {isFormTypeInvalid ? null : (
          <AppButtonWithMetamask
            mt={'20px'}
            disabled={disableSubmitBtn}
            onClick={() => {
              if (isSwitchNetwork) {
                switchChainHandler();
              } else {
                handleSubmit();
              }
            }}
            title={`${submitBtnTitle || ''}`}
          />
        )}
      </Flex>
    );
  };

  return (
    <Form
      onSubmit={(data) => {
        if (isDepositForm) {
          generateDepositDataHandler(data);
        } else {
          submitHandler(data);
        }
      }}
      initialValues={FormInitialValues}
      render={({ handleSubmit }) => (
        <Flex flexDir={'column'} mt="20px">
          <Flex
            flexDir={'column'}
            minW={'700px'}
            p={'20px 40px'}
            alignSelf={'center'}
            bgColor={'#1B1E26'}
            borderRadius={'16px'}
            border={'1px solid #494b51'}
          >
            <GroupSelect swapHandler={swapHandler} />
            <Flex height={'20px'} />
            {isDepositForm && renderDepositFieldsInfor(handleSubmit)}
            {(isWithdrawForm || isTransferForm) &&
              renderWithdrawFieldsInfor(handleSubmit)}
          </Flex>
        </Flex>
      )}
    />
  );
};

export default enhance(withSekeleton(FormBridge));
