import { SupportedChainId } from '@/constants/chains';
import { ContractOperationHook } from '@/interfaces/contract-operation';
import { capitalizeFirstLetter } from '@trustless-computer/dapp-core';

interface IParams<P, R> {
  operation: ContractOperationHook<P, R>;
  inscribeable?: boolean;
  chainId?: SupportedChainId;
  isCheckChainID?: boolean;
}

interface IContractOperationReturn<P, R> {
  run: (p: P) => Promise<R>;
}

const useContractOperation = <P, R>(
  args: IParams<P, R>,
): IContractOperationReturn<P, R> => {
  const { operation } = args;
  const { call } = operation();
  const run = async (params: P): Promise<R> => {
    try {
      // console.time('____metamaskCreateTxTime');
      const tx: R = await call({
        ...params,
      });
      // console.timeEnd('____metamaskCreateTxTime');

      return tx;
    } catch (err) {
      console.log('====================================');
      console.error(err);
      console.log('====================================');
      const reason = Object(err).reason;
      if (reason) {
        if (typeof reason === 'string') {
          throw new Error(reason);
        } else {
          throw new Error(capitalizeFirstLetter(Object(err).reason));
        }
      }
      throw err;
    }
  };

  return {
    run,
  };
};

export default useContractOperation;
