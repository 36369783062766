import { Input2 } from '@/components/Inputs/Input2';
import validator, { composeValidators } from '@/utils/validator';
import { NETWORK_SUPPORTING, isLayer2 } from '@constants/network';
import { useWeb3React } from '@web3-react/core';
import { useEffect, useMemo } from 'react';
import { Field, useForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Fields, FormInitialValues } from '../FormBridge.constants';
import { formBridgeActions } from '../state/reducer';
import { getFormBridgeInfo } from '../state/selector';

import { Flex, Text } from '@chakra-ui/react';

const ToInputField = () => {
  const {
    fromTokenSelected,
    fromNetworkSelected,
    formType,
    fromBalanceFormatedStr,
    generateDepositData,
    toNetworkSelected,
    toTokenSelected,
    isSwitchNetwork,
    toInputPlaceHolder,
    disableSubmitBtn,
    isBurnNativeToken,
    formInstance,
    isWithdrawForm,
    isCrossLayer2,
  } = useSelector(getFormBridgeInfo);

  const form = useForm();
  const dispatch = useDispatch();
  const { account: metasMaskAddress, chainId } = useWeb3React();

  useEffect(() => {
    if (form) {
      form.reset(FormInitialValues);
      form.resetFieldState(Fields.AMOUNT);
      form.resetFieldState(Fields.ADDRESS);
    }
  }, [fromTokenSelected, fromNetworkSelected, toNetworkSelected]);

  const validators = useMemo(() => {
    const validators: any[] = [];

    if (disableSubmitBtn) return undefined;

    switch (formType) {
      case 'Deposit':
      case 'CrossLayer2':
      case 'Transfer':
        validators.push(validator.addressRequired(), validator.validateEVMAddress());
        break;

      case 'Withdraw': {
        if (
          toNetworkSelected === NETWORK_SUPPORTING.BITCOIN ||
          toNetworkSelected === NETWORK_SUPPORTING.ORDINALS ||
          toNetworkSelected === NETWORK_SUPPORTING.RUNES
        ) {
          validators.push(
            validator.addressRequired(),
            validator.validateBTCAddress(),
          );
        } else if (toNetworkSelected === NETWORK_SUPPORTING.ETHEREUM) {
          validators.push(
            validator.addressRequired(),
            validator.validateEVMAddress(),
          );
        } else if (
          toNetworkSelected === NETWORK_SUPPORTING.TRUSTLESS_LAYER1 ||
          isLayer2(toNetworkSelected)
        ) {
          return undefined;
        }
        break;
      }
      default:
        return undefined;
    }
    return composeValidators(...validators);
  }, [
    fromTokenSelected,
    fromNetworkSelected,
    formType,
    fromBalanceFormatedStr,
    generateDepositData,
    toNetworkSelected,
    toTokenSelected,
    isSwitchNetwork,
    disableSubmitBtn,
    chainId,
    isBurnNativeToken,
    formInstance,
    isWithdrawForm,
  ]);

  useEffect(() => {
    form.reset({});
    form.resetFieldState(Fields.ADDRESS);
  }, [fromTokenSelected, fromNetworkSelected, chainId]);

  return (
    <Flex flexDir={'column'} gap={'16px'}>
      <Text fontSize={'16px'} color={'#fff'}>
        {'Receiving Address'}
      </Text>
      <Field name={Fields.ADDRESS} validate={validators}>
        {({ input, meta }) => {
          if (isCrossLayer2) {
            input.onChange(metasMaskAddress);
          }
          return (
            <Input2
              {...input}
              {...meta}
              onChange={(event: any) => {
                dispatch(formBridgeActions.setToInputAmount(event.target.value));
                input.onChange(event);
              }}
              id={Fields.ADDRESS}
              name={Fields.ADDRESS}
              placeholder={isCrossLayer2 ? metasMaskAddress : toInputPlaceHolder}
              type="text"
              spellCheck={false}
              onWheel={(e: any) => e?.target?.blur()}
              disabled={isCrossLayer2}
              // autoComplete="off"
            />
          );
        }}
      </Field>
    </Flex>
  );
};

export default ToInputField;
