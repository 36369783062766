import { switchChain } from '@/utils';
import { useSelector } from 'react-redux';
import { getChainInfor } from './state/selector';
import { logger } from '@/utils/logger';
import toast from 'react-hot-toast';
import { parseError } from '@/utils/errorHelper';

const enhanceSwitchChain = (WrappedComponent: any) => (props: any) => {
  const { supportChainID } = useSelector(getChainInfor);

  const switchChainHandler = async () => {
    console.log('[switchChainHandler] .... supportChainID ', supportChainID);
    try {
      if (supportChainID && supportChainID > 0) await switchChain(supportChainID);
    } catch (error) {
      logger('[switchChainHandler] ERROR: ', error);
      toast.error(parseError(error));
    }
  };

  return <WrappedComponent {...{ ...props, switchChainHandler }} />;
};
export default enhanceSwitchChain;
