import styled, { css } from 'styled-components';
import { MediaQueryBuilder } from '@/theme';
import { opacify } from '@theme/utils';
import Skeleton from 'react-loading-skeleton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  gap: 12px;
  input {
    min-height: 55px;
    min-width: 600px;
    caret-color: ${({ theme }) => theme.colors.primary.button};
  }
  ${MediaQueryBuilder(
    'xl',
    css`
      margin-left: unset;
    `,
  )}
  ${MediaQueryBuilder(
    'md',
    css`
      margin-left: unset;
      input {
        min-width: 450px;
      }
    `,
  )}
`;

const HistoryBox = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  align-self: center;
  align-items: center;
  overflow: hidden !important;
  min-height: 500px;
  max-height: 1000px;
  -ms-overflow-style: none;
  padding: 24px;
  margin-top: 32px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: ${opacify(70, '#1b1e26')};
  border-radius: 20px;

  ${MediaQueryBuilder(
    'md',
    css`
      width: 100%;
      padding: 10px;
      overflow: auto !important;
      min-width: 1000px;
    `,
  )}
`;

const HistoryList = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto !important;
  padding-right: 0;
  box-sizing: content-box;
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const Space = styled.div`
  margin-top: 40px;
`;

export const WrapperEmptyIcon = styled.div`
  flex: 1;
  display: flex;
  align-self: center;
  align-items: center;
`;

export const WrapperSubmitButton = styled.div`
  flex: 1;
  display: flex;
  align-self: center;
  align-items: center;
`;

const SubmitAddress = styled.button`
  padding: 15px 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  min-height: 55px;
  min-width: 120px;
  margin-left: 10px;
  background: ${({ theme }) => theme.colors.btn1};
  border-radius: 12px;

  :hover {
    opacity: 0.8;
  }

  :disabled {
    opacity: 0.5 !important;
  }
`;

export const AddressText = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: grey;
  margin-top: 28px;
  margin-right: 30px;
`;

const ClearCacheButton = styled.div`
  padding: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};

  min-width: 120px;
  border-radius: 12px;
  border: 1px solid #353945;
  margin-top: 24px;

  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const SkeletonList = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const SkeletonStyled = styled(Skeleton)`
  width: 100%;
  height: 40px;
  margin: 10px;
`;

export {
  Container,
  HeaderBox,
  SubmitAddress,
  ClearCacheButton,
  HistoryBox,
  HistoryList,
  SkeletonList,
  SkeletonStyled,
  Column,
};
