import Empty from '@/components/Empty';
import { Input2 } from '@/components/Inputs/Input2';
import useThrottle from '@/hooks/useThrottle';
import { fetchHistoryListAPI } from '@/services/history';
import { validateEVMAddress } from '@/utils';
import { parseError } from '@/utils/errorHelper';
import { useWeb3React } from '@web3-react/core';
import { orderBy } from 'lodash';
import { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import {
  filterTxByTcAddress,
  filterTxHistoryAPI,
} from '../Withdraw/Withdraw.localStorage';

import * as S from './History.styled';

import HistoryHeader from './components/HistoryHeader';
import HistoryItem from './components/HistoryItem';
import { cellarAllApproveTxsMap } from '../FormBridge/FormBridge.localStorage';
import { delay } from '@/utils/time';
import { Row } from '@/components/Row';
import Text from '@components/Text';
import { useRouter } from 'next/router';
import { useAppSelector } from '@/state/hooks';
import { getCurrentL2NetworkSelector } from '@/state/network/selector';
import Error from '@/componentsV2/Error';

const TIME_INTERVAL_API_HISTORY = 15000; // 15s

const NUMBER_OF_ITEM_DISPLAY = 1000; // will update after, need pagination

const HistoryPage = () => {
  const router = useRouter();
  const loopHistory = useRef<any>();

  const currentL2Network = useAppSelector(getCurrentL2NetworkSelector);

  const [addressTextInput, setAddressTextInput] = useState('');

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [historyList, setHistoryList] = useState<any[]>([]);

  const { query } = router;

  const { historyAddress: paramTCAddress } = query;

  const { account: metamaskAddress } = useWeb3React();

  const tcAddress = useMemo(() => {
    if (typeof paramTCAddress === 'string') {
      return paramTCAddress;
    }
    return metamaskAddress || undefined;
  }, [metamaskAddress, paramTCAddress]);

  const fetchHistory = async (tcAddress?: string) => {
    try {
      if (!tcAddress || tcAddress === '') return;
      const data = await fetchHistoryListAPI({
        address: tcAddress || '',
        network: currentL2Network,
      });
      let filterTxLocal = await filterTxHistoryAPI(data || [], []);
      filterTxLocal = filterTxByTcAddress(filterTxLocal, tcAddress);
      const resultSorted = orderBy(filterTxLocal, ['createdAt'], ['desc']).slice(
        0,
        NUMBER_OF_ITEM_DISPLAY,
      );
      setHistoryList(resultSorted);
    } catch (error: any) {
      console.log('[fetchHistory] Error ', error);
      toast.error(parseError(error));
      setHistoryList([]);
    } finally {
      setLoading(false);
    }
  };

  const clearIntervalHandler = () => {
    clearInterval(loopHistory.current);
    loopHistory.current = null;
  };
  const loopFetchHistory = (address: string) => {
    if (!loopHistory.current) {
      loopHistory.current = setInterval(() => {
        fetchHistory(address);
      }, TIME_INTERVAL_API_HISTORY);
    }
    if (checkAddressValid(address)) {
      fetchHistory(address);
    } else {
      clearIntervalHandler();
    }
    return () => {
      clearIntervalHandler();
    };
  };

  useEffect(() => {
    if (tcAddress) {
      setAddressTextInput(tcAddress);
      fetchHistory(tcAddress);
      loopFetchHistory(tcAddress);
    } else {
      setAddressTextInput('');
      setHistoryList([]);
      setLoading(false);
    }
  }, [tcAddress, currentL2Network]);

  const renderEmptyList = () => {
    return (
      <S.WrapperEmptyIcon>
        <Empty />
      </S.WrapperEmptyIcon>
    );
  };

  const renderHistoryList = () => {
    return (
      <>
        <HistoryHeader />
        {historyList && historyList.length > 0 ? (
          <S.HistoryList>
            {historyList.map((item, index) => (
              <HistoryItem
                key={`${item.uuid} ${index.toString()}`}
                item={item}
                index={index}
                addressInput={addressTextInput}
              />
            ))}
          </S.HistoryList>
        ) : (
          renderEmptyList()
        )}
      </>
    );
  };

  const renderContent = () => {
    return <S.HistoryBox>{renderHistoryList()}</S.HistoryBox>;
  };

  const checkAddressValid = (address: string) => {
    return address && validateEVMAddress(address);
  };

  const submitOnClick = useCallback(
    useThrottle(() => {
      setLoading(true);
      clearIntervalHandler();
      if (checkAddressValid(addressTextInput)) {
        setShowErrorMessage(false);
        loopFetchHistory(addressTextInput);
      } else {
        setHistoryList([]);
        setShowErrorMessage(true);
        setLoading(false);
      }
    }, 3000),
    [addressTextInput],
  );

  const clearCache = useCallback(
    useThrottle(async () => {
      await cellarAllApproveTxsMap();
      await delay(1000);
      window.location.reload();
    }, 3000),
    [addressTextInput],
  );

  const renderAddressBox = () => {
    return (
      <S.HeaderBox>
        <Text color="text2">Your History By Address:</Text>
        <Row gap={24} wrap="nowrap" className="content">
          <S.Column>
            <Input2
              onChange={(event: any) => {
                setAddressTextInput(event.target.value);
              }}
              id="HistoryByTCAddress"
              placeholder="Paste your address (Ex: 0xabc...xyz)"
              type="text"
              step="any"
              autoComplete="off"
              spellCheck={false}
              value={addressTextInput}
              onWheel={(e: any) => e?.target?.blur()}
            />
            {showErrorMessage && <Error>{`Address is inavlid.`}</Error>}
          </S.Column>
          <S.SubmitAddress onClick={submitOnClick}>Get History</S.SubmitAddress>
        </Row>
      </S.HeaderBox>
    );
  };

  const renderClearCache = () => {
    return (
      <Row justify="flex-end">
        <S.ClearCacheButton onClick={clearCache}>Clear Cache</S.ClearCacheButton>
      </Row>
    );
  };

  const renderLoadingSekeleton = () => {
    return (
      <S.HistoryBox>
        <S.SkeletonList>
          <S.SkeletonStyled count={8} />
        </S.SkeletonList>
      </S.HistoryBox>
    );
  };
  return (
    <S.Container>
      {renderAddressBox()}
      {isLoading ? (
        renderLoadingSekeleton()
      ) : (
        <>
          {renderContent()}
          {renderClearCache()}
        </>
      )}
    </S.Container>
  );
};

export default HistoryPage;
