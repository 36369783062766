export const Fields = {
  AMOUNT: 'amount',
  ADDRESS: 'address',
};

export const FormInitialValues = {
  [Fields.AMOUNT]: '',
  [Fields.ADDRESS]: '',
};

export const WAITING_APPROVAL_CONFIRMATION_TIMEOUT = 20; //20s
