/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
// import router from 'next/router';
import { getConnection } from '@/connection';
import { getChainInfor } from '@/containers/FormBridge/state/selector';
import { getWeb3InforSelector } from '@/state/web3React/selector';
import { switchChain } from '@/utils';
import { useWeb3React } from '@web3-react/core';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button, ButtonProps } from '@chakra-ui/react';

const withWeb3Connected = (Component: typeof Button) => (props: ButtonProps) => {
  const { isNeededConnectWeb3 } = useSelector(getWeb3InforSelector);
  const { connector, chainId } = useWeb3React();
  const { supportChainID } = useSelector(getChainInfor);

  const onConenctWeb3 = useCallback(async () => {
    try {
      const connection = getConnection(connector);
      if (!connection) {
        throw new Error('Get connection error.');
      }
      connection.connector.activate && connection.connector.activate();
      if (chainId !== supportChainID) {
        await switchChain(supportChainID);
      }
    } catch (error: any) {
      console.log('[onConenctWeb3] error ', error);
    }
  }, [connector, chainId, supportChainID]);

  return (
    <Component
      {...props}
      onClick={(e: any) => {
        isNeededConnectWeb3 ? onConenctWeb3() : props.onClick && props.onClick(e);
      }}
    ></Component>
  );
};

export default withWeb3Connected;
