import useLoading from '@/hooks/useLoading';
import convertUtils from '@/utils/convert';
import { parseError } from '@/utils/errorHelper';
import { NETWORK_SUPPORTING, isLayer2 } from '@constants/network';
import { useWeb3React } from '@web3-react/core';
import React from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { LOCAL_PENDING_STATUS } from '../History/History.constants';
import { getEventTypeParseNetwork } from '../History/History.types';
import { setTxBurnMetaMask } from '../Withdraw/Withdraw.localStorage';
import { Fields } from './FormBridge.constants';
import { getBridgeAddress } from './FormBridge.utils';
import { getFormBridgeInfo, getMaxErrorSelector } from './state/selector';
import useContractOperation from '@/hooks/contract-operations/useContractOperation';
import useTransfer, {
  ITransferParams,
} from '@/hooks/contract-operations/useTransfer';

const enhanceTransfer = (WrappedComponent: any) => (props: any) => {
  const { resetForm } = props;
  const {
    fromTokenSelected,
    allowanceAmount,
    toNetworkSelected,
    fromNetworkSelected,
    fromNetworkObject,
    toNetworkObject,
  } = useSelector(getFormBridgeInfo);

  const maxErrorMessage = useSelector(getMaxErrorSelector);

  const amountInputRef = React.useRef('');
  const allowanceAmountRef = React.useRef<string>();

  const { account: tcAddress } = useWeb3React();
  const { showLoading } = useLoading();

  const { run: transferExecute } = useContractOperation<ITransferParams, string>({
    operation: useTransfer,
    inscribeable: false,
  });

  const tokenAddress = React.useMemo(() => {
    return fromTokenSelected?.tcTokenID;
  }, [fromTokenSelected?.tcTokenID]);

  React.useEffect(() => {
    allowanceAmountRef.current = allowanceAmount;
  }, [allowanceAmount]);

  const bridgeAddress = React.useMemo(() => {
    return getBridgeAddress({
      network: toNetworkSelected,
      token: fromTokenSelected,
    });
  }, [toNetworkSelected, fromTokenSelected]);

  // TO DO

  const onTransfer = async (burnAmount: string, receiverAddress: string) => {
    if (
      !fromTokenSelected ||
      !bridgeAddress ||
      !tokenAddress ||
      !burnAmount ||
      !receiverAddress
    ) {
      return;
    }
    try {
      const payload = {
        bridgeAddress: bridgeAddress,
        tokenAddress: tokenAddress,
        burnAmount: burnAmount,
        receiver: receiverAddress,
        toNetworkChainID: toNetworkObject?.chainId || 9999,
      };

      let result: any;
      const chainID = toNetworkObject?.chainId;
      if (!chainID || chainID < 0) {
        throw 'ChainID UNKNOW';
      }

      const layer2Payload = {
        tokenAddress: payload.tokenAddress,
        amount: payload.burnAmount,
        receiver: payload.receiver,
        toNetworkChainID: chainID,
      };

      // TO DO
      result = await transferExecute(layer2Payload);
      return result;
    } catch (error) {
      throw error;
    }
  };

  const onTransferHandler = async (data: any) => {
    console.log('[onTransferHandler] data ', data);
    try {
      if (!data || maxErrorMessage) return;

      showLoading(true);
      const amount = data[Fields.AMOUNT];
      const receiverAddress = data[Fields.ADDRESS];

      if (!fromTokenSelected || !amount || !receiverAddress || !tcAddress) return;

      const tokenAddress = fromTokenSelected.tcTokenID!;
      amountInputRef.current = amount;

      const amountStr = convertUtils.toBigNumberNonRound(amount, 18);
      // const burnResult: any = await onBurn(amountStr, receiverAddress);
      const burnResult: any = await onTransfer(amountStr, receiverAddress);

      console.log('[DEBUG] Burn Result ', burnResult);

      if (burnResult?.hash) {
        //Save TX Burn Metamask to LocalStorage
        await setTxBurnMetaMask({
          txHash: burnResult.hash,
          amountHumanRead: amount,
          amountBN: amountStr,
          btcWithdrawAddress: receiverAddress,
          tcAddress: tcAddress,
          createdAt: new Date().getTime(),
          isL1ToL2:
            fromNetworkSelected === NETWORK_SUPPORTING.TRUSTLESS_LAYER1 &&
            isLayer2(toNetworkSelected),
          type: getEventTypeParseNetwork(fromNetworkSelected, toNetworkSelected),
          symbol: fromTokenSelected?.symbol,
          status: LOCAL_PENDING_STATUS,
          amountTc: amountStr,
          fromNetwork: fromNetworkSelected,
          toNetwork: toNetworkSelected,
          toNetworkTitle: toNetworkObject?.networkTitle || '',
          fromNetworkTitle: fromNetworkObject?.networkTitle || '',
        });

        toast.success('Transfer Processing');
        resetForm && resetForm();
      }
    } catch (error: any) {
      console.log('[onTransferHandler] ERROR: ', error);
      toast.error(parseError(error));
    } finally {
      showLoading(false);
    }
  };

  return (
    <>
      <WrappedComponent {...{ ...props, onTransferHandler }} />
    </>
  );
};
export default enhanceTransfer;
