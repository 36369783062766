import TokenView from '@/componentsV2/TokenView';
import { getToTokenSelectedSelector } from '@/containers/FormBridge/state/selector';
import { addToken } from '@/utils';
import { getIconUrl } from '@/utils/token';
import { Flex, Text } from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import { isEmpty } from 'lodash';
import debounce from 'lodash/debounce';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { getFormBridgeInfo } from '../state/selector';
import { ZERO_ADDRESS } from '@/constants/address';

const ToTokenSelected = () => {
  const { toNetworkSelected, toNetworkObject, isDepositForm } =
    useSelector(getFormBridgeInfo);
  const { connector, chainId } = useWeb3React();

  const requireChainID = toNetworkObject?.chainId;
  const toTokenSelected = useSelector(getToTokenSelectedSelector);

  const tokenName = toTokenSelected ? toTokenSelected?.symbol : '';
  const tokenLogoUrl = toTokenSelected
    ? getIconUrl({
        token: toTokenSelected,
      })
    : '';

  const isAddToken = React.useMemo(() => {
    return isDepositForm && toTokenSelected?.tcTokenID !== ZERO_ADDRESS;
  }, [isDepositForm, toTokenSelected]);

  const onImportToken = debounce(async () => {
    if (!toTokenSelected) return;
    if (chainId === undefined) {
      toast.error(
        `Please connect to Metamask before importing the ${toTokenSelected?.symbol} token.`,
      );
      return;
    }

    if (!requireChainID || requireChainID < 0) {
      toast.error(`requireChainID invalid`);
      return;
    }

    try {
      await addToken({
        tokenAddress: toTokenSelected.tcTokenID,
        symbol: toTokenSelected.symbol,
        decimals: toTokenSelected.decimals,
        image: getIconUrl({
          token: toTokenSelected,
        }),
        connector,
        currentChainID: chainId,
        requireChainID: requireChainID,
      });
    } catch (error: any) {
      console.log('ADD TOKEN ERROR: ', error);
      toast.error(`${error?.message}`);
    }
  }, 300);

  const disbaled = !toNetworkSelected;

  return (
    <Flex
      flex={1}
      my={'4px'}
      px={'8px'}
      borderRadius={'4px'}
      flexDir={'row'}
      align={'center'}
      justifyContent={'space-between'}
      bgColor={'#1B1E26'}
      opacity={disbaled ? 0.5 : 1}
      _hover={{
        bg: disbaled ? '' : '',
        cursor: disbaled ? 'not-allowed' : '',
      }}
    >
      {!isEmpty(tokenName) && (
        <TokenView name={tokenName} iconUrl={tokenLogoUrl}></TokenView>
      )}
      {isAddToken && !isEmpty(tokenName) && (
        <Text
          fontSize={15}
          fontWeight={600}
          onClick={onImportToken}
          color="#00ff"
          _hover={{
            cursor: 'pointer',
            opacity: 0.8,
          }}
        >
          {'Import token'}
        </Text>
      )}
    </Flex>
  );
};

export default ToTokenSelected;
