import NetworkView from '@/componentsV2/NetworkView';
import useNetworkHook from '@/state/network/hook';
import { INetwork } from '@/state/network/types';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import useFormBridge from '../state/hook';
import { formBridgeActions } from '../state/reducer';

const FromNetworkSelect = () => {
  const dispatch = useDispatch();

  const { fromNetworkSelected, fromNetworkObject } = useFormBridge();
  const { networkListByUsing } = useNetworkHook();

  const itemOnClick = (item: INetwork) => {
    if (item.networkName !== fromNetworkSelected) {
      dispatch(formBridgeActions.setFromNetwork(item.networkName));
    }
  };

  const isEmptyNetwork = !fromNetworkSelected;

  return (
    <Menu>
      <MenuButton
        minH={'50px'}
        my={'4px'}
        px={'8px'}
        h={'100%'}
        borderRadius={'4px'}
        bgColor={'#1B1E26'}
        _hover={{ bg: '#BFBFBFAA' }}
      >
        <Flex flexDir={'row'} align={'center'} justifyContent={'space-between'}>
          {isEmptyNetwork ? (
            <Text fontSize={'16px'} fontWeight={500}>
              {'Select network'}
            </Text>
          ) : (
            <NetworkView
              name={fromNetworkObject?.networkTitle || ''}
              iconUrl={fromNetworkObject?.networkLogo}
            />
          )}
          <ChevronDownIcon />
        </Flex>
      </MenuButton>
      <MenuList minW={'300px'} maxH={'400px'} overflowY="auto">
        {networkListByUsing &&
          networkListByUsing.map((item, index) => {
            return (
              <MenuItem
                key={`${item}-${index}`}
                onClick={() => itemOnClick(item)}
                minH={'50px'}
              >
                <NetworkView
                  name={item?.networkTitle || ''}
                  iconUrl={item?.networkLogo}
                />
              </MenuItem>
            );
          })}
      </MenuList>
    </Menu>
  );
};

export default FromNetworkSelect;
