import { Flex, Text } from '@chakra-ui/react';
import { NextPage } from 'next';
import { Info } from 'react-feather';

const ErrorPage: NextPage<{
  errorMesage?: string;
  errorCodes?: number[];
}> = (props) => {
  const { errorMesage, errorCodes } = props;
  return (
    <Flex
      flex={1}
      flexDir={'column'}
      align={'center'}
      justify={'center'}
      w={'100dvw'}
      h={'100dvh'}
      color={'#fff'}
      gap={'30px'}
    >
      <Info
        color="white"
        style={{
          width: 50,
          height: 50,
        }}
      />
      <Text fontSize={'30px'}>{errorMesage ? errorMesage : 'Page not found'}</Text>
      <Text fontSize={'22px'}>
        {errorCodes?.map((errCode) => {
          return `Error Code: [${errCode}]`;
        })}
      </Text>
    </Flex>
  );
};

export default ErrorPage;
