import { Flex, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { getFormBridgeInfo, getFromBalanceSelector } from '../state/selector';
const FromBalanceBox = () => {
  let balance;
  const { fromBalanceFormatedStr, fromTokenSelected, isSwitchNetwork, formType } =
    useSelector(getFormBridgeInfo);

  const { fromBalanceFormatedStr: withdrawBalance } = useSelector(
    getFromBalanceSelector,
  );

  if (!fromTokenSelected || isSwitchNetwork) return null;

  if (formType === 'Deposit' || formType === 'Transfer') {
    balance = fromBalanceFormatedStr;
  }
  if (formType === 'Withdraw') {
    balance = withdrawBalance;
  }
  return (
    <Flex align={'center'} justifyContent={'left'}>
      <Text
        fontWeight={600}
      >{`Balance: ${balance} ${fromTokenSelected.symbol?.toUpperCase()}`}</Text>
    </Flex>
  );
};

export default FromBalanceBox;
