'use client';

import { Tab, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import { withLayout } from '@/layouts';
import { Flex } from '@chakra-ui/react';
import { useState } from 'react';
import FormBridge from '../FormBridge';
import FormUpdater from '../FormBridge/state/updater';
import HistoryPage from '../History';
import enhance from './Home.enhance';

const Home = () => {
  const [activeTab, setChatTabIndex] = useState<number>(0);

  const onChangeTab = (index: number) => {
    setChatTabIndex(index);
  };

  return (
    <Flex flexDir={'column'} align={'center'} w="100%">
      <FormUpdater />
      <Tabs
        isLazy
        lazyBehavior="keepMounted"
        onChange={onChangeTab}
        defaultIndex={activeTab}
        w="100%"
      >
        <Flex flexDir={'column'} align={'center'} w={'100%'}>
          <Flex
            flexDir={'row'}
            justify={'center'}
            align={'center'}
            maxW={'max-content'}
            bgColor={'#131419'}
            borderRadius={'40px'}
            w={'100%'}
            p="5px"
          >
            <Tab
              px={'24px'}
              py={'12px'}
              minW={'100px'}
              borderRadius={'40px'}
              fontWeight={600}
              _selected={{
                bgColor: '#3772ff',
              }}
            >
              Transfer
            </Tab>
            <Tab
              px={'24px'}
              py={'12px'}
              minW={'100px'}
              borderRadius={'40px'}
              fontWeight={600}
              _selected={{
                bgColor: '#3772ff',
              }}
            >
              History
            </Tab>
          </Flex>
        </Flex>
        <TabPanels flex={1} w={'100%'}>
          <TabPanel>
            <FormBridge />
          </TabPanel>
          <TabPanel>
            <HistoryPage />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default enhance(withLayout(Home));
