import { CDN_URL } from '@/configs';
import { Token } from '@/state/tokens/types';

export const getIconUrl = ({
  token,
  symbol,
}: {
  token?: Token;
  symbol?: string;
}) => {
  let url = token?.logo
    ? token.logo
    : 'https://cdn.trustless.computer/upload/1683530065704444020-1683530065-default-coin.svg';
  return url;
};

export const getIconUrlFromHistory = ({
  token,
  symbol,
}: {
  token?: Token;
  symbol?: string;
}) => {
  // Default url using through App
  let url = 'https://cdn.trustless.domains/icons/ic-bridge.svg';
  if (token?.logo) {
    // logo URL from API
    url = token?.logo;
  } else {
    // get Symbol from Token => Convert URL
    const symbolStr = token?.symbol || symbol;
    if (symbolStr) {
      const formatedSymbol = String(symbolStr).toLowerCase();
      url = `${CDN_URL}/icons/${formatedSymbol}.png`;
    }
  }

  return url;
};
