import { useSelector } from 'react-redux';
import {
  getNetworkByNameSelector,
  reducerSelector as networkStateSelector,
} from './selector';

const useNetworkHook = () => {
  const state = useSelector(networkStateSelector);
  const getNetworkByNameFunc = useSelector(getNetworkByNameSelector);

  return {
    ...state,
    getNetworkByNameFunc,
  };
};

export default useNetworkHook;
