/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { STRINGS } from '@/constants/strings';
import { RootState } from '@/state';
import { getWeb3InforSelector } from '@/state/web3React/selector';
import { formatAmountFactory, humanReadable } from '@/utils/format';
import { isLayer2, isOutChain, NETWORK_SUPPORTING } from '@constants/network';
import {
  getTokenGroupNetworkSelector,
  getTokenMapSelector,
} from '@state/tokens/selector';
import { orderBy } from 'lodash';
import { createSelector } from 'reselect';
import { isLayer1Network } from '../FormBridge.utils';

import {
  getNetworkByNameSelector,
  getCurrentL2NetworkSelector,
  getNetworkNameAvailableListSelector,
} from '@/state/network/selector';
import { depositDataFormmater, estimateDataFormmater } from './helper';
import { ZERO_ADDRESS } from '@/constants/address';
import BigNumber from 'bignumber.js';

const formReducerSelector = (state: RootState) => state.formBridgeReducer;

// From Data

const getFromNetworkSelected = createSelector(
  formReducerSelector,
  (state) => state.fromNetworkSelected,
);
const getFromTokenSelected = createSelector(
  formReducerSelector,
  (state) => state.fromTokenSelected,
);
const getFromInputAmount = createSelector(
  formReducerSelector,
  (state) => state.fromNetworkSelected,
);

// To Data
const getToNetworkSelected = createSelector(
  formReducerSelector,
  (state) => state.toNetworkSelected,
);
const getToTokenSelected = createSelector(
  formReducerSelector,
  (state) => state.toTokenSelected,
);
const getToInputAddress = createSelector(
  formReducerSelector,
  (state) => state.toInputAddress,
);

const getChainInfor = createSelector(
  [formReducerSelector, getWeb3InforSelector, getNetworkByNameSelector],
  (fromBridgeData, web3Data, getNetworkByNameFunc) => {
    const { fromNetworkSelected, formType, fromNetworkObjSelected } = fromBridgeData;

    let isCorrectChain = false;
    let supportChainID = fromNetworkObjSelected?.chainId || -1;
    let supportChainName = fromNetworkObjSelected?.networkTitle || '';

    const currentChainMetaMask = web3Data?.chainId;

    if (fromNetworkSelected) {
      switch (formType) {
        case 'Deposit':
          //Deposit Centralize, not use metamask => TO DO
          break;
        case 'Withdraw':
          {
            const networkObj = getNetworkByNameFunc(fromNetworkSelected);
            supportChainID = networkObj?.chainId || -1;
            supportChainName = networkObj?.networkTitle || '';
          }
          break;
        default:
          break;
      }
    }

    isCorrectChain = currentChainMetaMask === supportChainID;

    return {
      web3Data,
      isSwitchNetwork: !isCorrectChain,
      isCorrectChain,
      supportChainID,
      supportChainName,
      currentChainMetaMask,
    };
  },
);

const getEstimateWithdrawSelector = createSelector(formReducerSelector, (state) => {
  const { estimateWithdrawLoading, estimateWithdrawFetched, estimateWithdrawData } =
    state;

  return {
    isLoading: estimateWithdrawLoading,
    isFetched: estimateWithdrawFetched,
    data: estimateDataFormmater(estimateWithdrawData),
  };
});

const getSubmitBtnInfor = createSelector(
  [
    formReducerSelector,
    getChainInfor,
    getWeb3InforSelector,
    getEstimateWithdrawSelector,
  ],
  (fromBridgeData, chainInfor, web3Infor, estimateWithdrawData) => {
    const {
      fromNetworkSelected,
      formType = 'Deposit',
      toNetworkSelected,
      fromTokenSelected,
      fromNetworkObjSelected,
    } = fromBridgeData;

    const { isNeededConnectWeb3, isActivating, isActive } = web3Infor;
    const { data: estimateData } = estimateWithdrawData;

    const { isSwitchNetwork, isCorrectChain, supportChainName } = chainInfor;

    let submitBtnTitle = 'Transfer';
    let disableSubmitBtn = false;
    if (!fromNetworkSelected) {
      disableSubmitBtn = true;
    } else {
      if (formType === 'Deposit') {
        if (isNeededConnectWeb3) {
          submitBtnTitle = 'Connect Wallet';
        } else {
          if (isActivating) {
            submitBtnTitle = 'Connecting...';
          }
          if (isActive) {
            if (fromNetworkSelected && toNetworkSelected) {
              submitBtnTitle =
                isCorrectChain && !isSwitchNetwork
                  ? 'Transfer'
                  : `Switch to ${supportChainName}`;
            } else {
              disableSubmitBtn = true;
            }
          }
        }
      } else {
        if (
          toNetworkSelected === NETWORK_SUPPORTING.BITCOIN &&
          fromTokenSelected?.type?.toLowerCase() === 'brc20' &&
          estimateData?.isNeedBTCBalanceMore
        ) {
          disableSubmitBtn = true;
        }
        if (isNeededConnectWeb3) {
          submitBtnTitle = 'Connect MetaMask';
        } else if (fromNetworkSelected && isLayer1Network(fromNetworkSelected)) {
          submitBtnTitle = 'Connect Trustless Wallet';
        } else {
          submitBtnTitle =
            isCorrectChain && !isSwitchNetwork
              ? 'Transfer'
              : `Switch to ${fromNetworkObjSelected?.networkTitle}`;
        }
      }
    }

    const result = {
      ...web3Infor,
      submitBtnTitle,
      disableSubmitBtn,
      isNeededConnectWeb3,
    };

    return result;
  },
);

const getFormTypeSelector = createSelector(
  [formReducerSelector],
  (formBridgeData) => {
    const { formType } = formBridgeData;

    const isDepositForm = formType === 'Deposit';
    const isWithdrawForm = formType === 'Withdraw';
    const isCrossLayer2 = formType === 'CrossLayer2';
    const isTransferForm = formType === 'Transfer';
    const isFormTypeInvalid = formType === 'Invalid';

    return {
      formType,
      isDepositForm,
      isWithdrawForm,
      isCrossLayer2,
      isFormTypeInvalid,
      isTransferForm,
    };
  },
);

const getInputDecorateSelector = createSelector(
  [formReducerSelector],
  (fromBridgeData) => {
    const { formType, toNetworkSelected } = fromBridgeData;

    let disableToNetworkSelect = false;
    let toInputPlaceHolder = STRINGS.paste_tc_address;

    //Deposit Centralize via BE service
    if (formType === 'Deposit') {
      toInputPlaceHolder = `${STRINGS.EVMAddressPlaceholder}`;
    }

    //Otherwise is Withdraw
    else {
      //Output is Bitcoin Network
      if (
        toNetworkSelected === NETWORK_SUPPORTING.BITCOIN ||
        toNetworkSelected === NETWORK_SUPPORTING.ORDINALS ||
        toNetworkSelected === NETWORK_SUPPORTING.RUNES
      ) {
        toInputPlaceHolder = `${STRINGS.pasteBTCAddress}`;
      }

      //Output is Ethereum Network
      else if (toNetworkSelected === NETWORK_SUPPORTING.ETHEREUM) {
        toInputPlaceHolder = `${STRINGS.EVMAddressPlaceholder}`;
      }
      //Output is Another (NOS, L1, L2, L2' ....) Network
      else {
        toInputPlaceHolder = `${STRINGS.EVMAddressPlaceholder}`;
      }
    }

    const result = {
      disableToNetworkSelect,
      toInputPlaceHolder,
    };

    return result;
  },
);

const getFormBridgeInfo = createSelector(
  [
    formReducerSelector,
    getChainInfor,
    getSubmitBtnInfor,
    getFormTypeSelector,
    getNetworkByNameSelector,
    getInputDecorateSelector,
  ],
  (
    fromBridgeData,
    chainInfor,
    submitBtnInfo,
    formTypeData,
    getNetworkByNameFunc,
    inputDecorateData,
  ) => {
    const {
      toNetworkSelected,
      fromTokenSelected,
      formType,
      fromBalance,
      fromNetworkSelected,
    } = fromBridgeData;

    const { isSwitchNetwork } = chainInfor;

    let isL1ToL2 =
      isLayer1Network(fromNetworkSelected) && isLayer2(toNetworkSelected);
    let isL2ToL1 =
      isLayer1Network(toNetworkSelected) && isLayer2(fromNetworkSelected);

    const isBrc20 =
      fromTokenSelected && fromTokenSelected.type?.toUpperCase() === 'BRC20';
    const isBTCToken =
      fromTokenSelected &&
      fromTokenSelected.symbol?.toUpperCase() === 'BTC' &&
      fromTokenSelected.name?.toUpperCase() === 'BITCOIN';

    let isNativeToken = fromTokenSelected?.isNativeBridge;
    if (fromTokenSelected?.tokenID === ZERO_ADDRESS) {
      isNativeToken = true;
    }

    isNativeToken = !!isNativeToken;

    const fromBalanceFormatPrice = formatAmountFactory(
      fromBalance || '0.0',
      formType === 'Withdraw' || formType === 'Transfer'
        ? 18
        : fromTokenSelected?.decimals,
    );
    const {
      priceBN: fromBalanceBN,
      priceFormatedBN: fromBalanceFormatedBN,
      priceFormatedStr: fromBalanceFormatedStr,
    } = fromBalanceFormatPrice;

    const fromNetworkSelectedNew = fromNetworkSelected ?? NETWORK_SUPPORTING.BITCOIN;
    // const toNetworkSelectedNew = toNetworkSelected ?? NETWORK_SUPPORTING.BITCOIN;

    const fromNetworkObject = getNetworkByNameFunc(fromNetworkSelectedNew);
    const toNetworkObject = toNetworkSelected
      ? getNetworkByNameFunc(toNetworkSelected)
      : undefined;

    let isBurnNativeToken = false;
    let isTransferBVMFromL1ToNOS =
      fromNetworkSelected === NETWORK_SUPPORTING.TRUSTLESS_LAYER1 &&
      toNetworkSelected === NETWORK_SUPPORTING.TRUSTLESS_LAYER2 &&
      fromTokenSelected?.symbol?.toUpperCase() === 'BVM';

    let isTransferBVMFromNOSToL1 =
      fromNetworkSelected === NETWORK_SUPPORTING.TRUSTLESS_LAYER2 &&
      toNetworkSelected === NETWORK_SUPPORTING.TRUSTLESS_LAYER1 &&
      fromTokenSelected?.symbol?.toUpperCase() === 'BVM';

    if (isNativeToken && isOutChain(toNetworkSelected) && formType === 'Withdraw') {
      isBurnNativeToken = true;
    }

    const tokenSymbol = fromTokenSelected?.symbol?.toUpperCase();

    const result = {
      ...fromBridgeData,
      ...submitBtnInfo,
      ...chainInfor,
      ...formTypeData,
      ...inputDecorateData,

      fromNetworkSelected: fromNetworkSelectedNew,
      fromNetworkObject,
      toNetworkObject,

      isNativeToken,
      isSwitchNetwork,

      isBrc20,
      isBTCToken,

      fromBalanceBN,
      fromBalanceFormatedBN,
      fromBalanceFormatedStr,

      isL1ToL2,
      isL2ToL1,
      isBurnNativeToken,
      isTransferBVMFromL1ToNOS,
      isTransferBVMFromNOSToL1,

      tokenSymbol,
    };

    return result;
  },
);

const getFromBalanceSelector = createSelector(
  [formReducerSelector],
  (fromBridgeData) => {
    const { fromBalance } = fromBridgeData;

    const fromBalanceFormatPrice = formatAmountFactory(
      fromBalance || '0.0',
      18, //Hard code, TC always decimals = 18
    );
    const {
      priceBN: fromBalanceBN,
      priceFormatedBN: fromBalanceFormatedBN,
      priceFormatedStr: fromBalanceFormatedStr,
    } = fromBalanceFormatPrice;

    const result = {
      fromBalanceBN,
      fromBalanceFormatedBN,
      fromBalanceFormatedStr,
    };
    return result;
  },
);

const getGenerateDepositSelector = createSelector(formReducerSelector, (state) => {
  const {
    generateDepositData,
    generateDepositDataLoading,
    generateDepositDataFetched,
  } = state;

  return {
    isLoading: generateDepositDataLoading,
    isFetched: generateDepositDataFetched,
    data: depositDataFormmater(generateDepositData),
  };
});

const getFromInstanceSelector = createSelector(
  [formReducerSelector],
  (fromBridgeData) => {
    const { formInstance } = fromBridgeData;
    return formInstance;
  },
);

const getEstimateTimeProcess = createSelector(
  [getFormBridgeInfo],
  (fromBridgeInfo) => {
    const { fromTokenSelected, fromNetworkSelected, toNetworkSelected, formType } =
      fromBridgeInfo;

    if (!formType || !fromNetworkSelected || !fromTokenSelected) return null;

    let estTime = 0;

    switch (formType) {
      case 'Transfer': {
        estTime = 2;
        break;
      }
      case 'Deposit':
        switch (fromNetworkSelected) {
          // BTC | Ordinal -> L1 | L2
          case NETWORK_SUPPORTING.BITCOIN:
          case NETWORK_SUPPORTING.ORDINALS:
          case NETWORK_SUPPORTING.RUNES:
            estTime = 30;
            break;

          // ETH ->  L1 | L2
          case NETWORK_SUPPORTING.ETHEREUM:
            estTime = 10;
            break;
          default:
            estTime = 0;
        }
        break;
      case 'Withdraw':
        {
          switch (fromNetworkSelected) {
            default:
              switch (toNetworkSelected) {
                case NETWORK_SUPPORTING.ORDINALS:
                case NETWORK_SUPPORTING.BITCOIN:
                case NETWORK_SUPPORTING.RUNES:
                  estTime = 11;
                  break;
                case NETWORK_SUPPORTING.ETHEREUM:
                  estTime = 3;
                  break;
                default:
                  estTime = 3;
              }
              break;
          }
        }
        break;
      default:
        estTime = 11;
        break;
    }

    const unit = estTime > 1 ? 'mins' : 'min';

    const estTimeStr = `${estTime} ${formType === 'Transfer' ? 's' : unit}`;

    return {
      estTime,
      estTimeStr,
    };
  },
);

const getFromTokenListSelector = createSelector(
  getTokenGroupNetworkSelector,
  getFromNetworkSelected,
  getCurrentL2NetworkSelector,
  (tokenGroupNetwork, fromNetworkSelected, currentL2Network) => {
    let tokens =
      (fromNetworkSelected && tokenGroupNetwork[fromNetworkSelected]) || [];

    //Flow Deposit (Only support token can deposit to Current Layer 2)
    if (fromNetworkSelected !== currentL2Network?.toLowerCase()) {
      tokens = tokens.filter((t) => {
        let listNetwork = Object.keys(t.tokenAddress) || [];

        if (currentL2Network) {
          if (listNetwork.includes(currentL2Network)) {
            return true;
          }
          return false;
        } else {
          return false;
        }
      });
    } else {
    }

    // Sort token by symbol
    tokens = orderBy(
      tokens,
      (token) => [
        token.symbol?.toUpperCase() === 'BVM',
        token.symbol?.toUpperCase() === 'BTC',
        token.symbol?.toUpperCase() === 'ETH',
        token.symbol?.toUpperCase() === 'USDT',
        token.symbol?.toUpperCase() === 'USDC',
      ],
      ['desc'],
    );

    return tokens;
  },
);

const getToNetworkListSelector = createSelector(
  getFormBridgeInfo,
  getCurrentL2NetworkSelector,
  getNetworkByNameSelector,
  getNetworkNameAvailableListSelector,
  (
    getFormBridge,
    currentL2Network,
    getNetworkByNameFunc,
    fromNetworkListAvailable,
  ) => {
    const { fromNetworkSelected, fromTokenSelected } = getFormBridge;

    if (fromTokenSelected) {
      const { tokenAddress, bridgeAddress } = fromTokenSelected;

      const isWidthraw =
        currentL2Network?.toLowerCase() === fromNetworkSelected?.toLowerCase();

      // Flow Withdraw
      // Get List Network from Bridge Address attribute of TOKEN
      if (isWidthraw) {
        let listNetwork = Object.keys(bridgeAddress) || [];

        if (currentL2Network) {
          listNetwork = listNetwork.filter(
            (network) =>
              network.toLowerCase() !== currentL2Network.toLowerCase() &&
              fromNetworkListAvailable.includes(network),
          );

          // console.log('LOG 1 ==== ', listNetwork);

          listNetwork = listNetwork.filter((networkStr) => {
            const networkObj = getNetworkByNameFunc(networkStr);
            return networkObj?.isL2aas === false;
          });

          //Transfer (Feature Transfer)
          listNetwork.push(currentL2Network);

          // console.log('LOG 2 ==== ', listNetwork);
          return listNetwork;
        } else {
          return listNetwork;
        }
      }

      // Flow Deposit
      // Get List Network from Token Address attribute of TOKEN
      else {
        let listNetwork = Object.keys(tokenAddress) || [];
        //Filter network == current layer2
        listNetwork = listNetwork.filter(
          (network) => network.toLowerCase() === currentL2Network?.toLowerCase(),
        );

        if (listNetwork && listNetwork.length > 0) {
          return listNetwork;
        }
        return [];
      }
    } else {
      return [];
    }
  },
);

export const toNetworkFactorySelectorV2 = createSelector(
  getFormBridgeInfo,
  getToNetworkListSelector,
  (getFormBridge, toNetworkList) => {
    const { fromNetworkSelected, fromTokenSelected, toNetworkObject } =
      getFormBridge;

    let title = 'Select network';
    let disbaled = false;

    if (!fromNetworkSelected || !fromTokenSelected) {
      disbaled = true;
    } else {
      //Exist network can be select
      if (toNetworkList && toNetworkList.length > 0) {
        disbaled = false;
      } else {
        if (toNetworkObject) {
          title = toNetworkObject.networkName;
          disbaled = false;
        } else {
          title = 'Un-Supported';
          disbaled = true;
        }
      }
    }

    return {
      title,
      disbaled,
    };
  },
);

const getMaxBalanceSelector = createSelector(
  [getFormBridgeInfo, getFromBalanceSelector],
  (fromBridgeData, maxBalanceObj) => {
    const {
      fromBalanceFormatedStr: depositBalance,
      fromTokenSelected,
      isSwitchNetwork,
      formType,
    } = fromBridgeData;

    const { fromBalanceFormatedStr: withdrawBalance } = maxBalanceObj;

    let maxBalance;

    if (!fromTokenSelected || isSwitchNetwork) {
      maxBalance = '0';
    } else if (formType === 'Deposit' || formType === 'Transfer') {
      maxBalance = depositBalance;
    } else if (formType === 'Withdraw') {
      maxBalance = withdrawBalance;
    } else {
      maxBalance = '0';
    }

    return maxBalance;
  },
);

const getMaxErrorSelector = createSelector([formReducerSelector], (reducer) => {
  return reducer.error;
});

const getToTokenSelectedSelector = createSelector(
  [getFormBridgeInfo, getTokenMapSelector],
  (formBridgeInfo, tokenMap) => {
    const { fromTokenSelected, toNetworkSelected } = formBridgeInfo;
    if (!fromTokenSelected || !toNetworkSelected) return undefined;
    const key = `[${toNetworkSelected}]-[${fromTokenSelected.tokenAddress[toNetworkSelected]}]`;
    const token = tokenMap[key];

    // console.log('[getToTokenSelectedSelector] -- ', {
    //   key,
    //   token,
    //   tokenMap,
    // });

    return token;
  },
);

const getFeeBurnNativeTokenSelector = createSelector(
  formReducerSelector,
  (formState) => {
    const { feeBurnNativeToken } = formState;
    const dataFormated = formatAmountFactory(
      feeBurnNativeToken || '0',
      18,
      BigNumber.ROUND_CEIL,
    );
    return {
      feeBurnNativeToken_BN: dataFormated.priceBN,
      feeBurnNativeTokenFormated_BN: dataFormated.priceFormatedBN,
      feeBurnNativeTokenFormated_Str: humanReadable(
        dataFormated.priceFormatedStr || '0',
        9,
      ),
    };
  },
);

export {
  formReducerSelector,
  getChainInfor,
  getEstimateTimeProcess,
  getEstimateWithdrawSelector,
  getFormBridgeInfo,
  //

  getFromBalanceSelector,
  getFromInputAmount,
  //
  getFromInstanceSelector,
  // From Data
  getFromNetworkSelected,
  getFromTokenSelected,
  getGenerateDepositSelector,
  getSubmitBtnInfor,
  getToInputAddress,
  // To Data
  getToNetworkSelected,
  getToTokenSelected,
  //Max Balance
  getMaxBalanceSelector,
  getMaxErrorSelector,
  //
  getToTokenSelectedSelector,
  //Burn NativeToken
  getFeeBurnNativeTokenSelector,
  //NEW VERSION_2
  getFromTokenListSelector,
  getToNetworkListSelector,
};
