import { CHAINS_INFO, SupportedChainId } from '@/constants/chains';
import { IResourceChain } from '@/interfaces/chain';
import Web3 from 'web3';
import { Connector } from '@web3-react/types';
import { getNetworkListAPI } from '@/services/networks';

const API_PATH = 'https://chainid.network/chains.json';

const getChainList = async (): Promise<Array<IResourceChain>> => {
  try {
    const [chains, networkL2] = await Promise.all([
      await fetch(API_PATH),
      await getNetworkListAPI(),
    ]);
    const data = await chains.json();

    const networkList = networkL2.map((network) => {
      const nativeCurrency = 'BVM';
      return {
        name: network.networkTitle,
        chainId: Number(network.chainId),
        nativeCurrency: {
          name: nativeCurrency,
          symbol: nativeCurrency,
          decimals: 18,
        },
        explorers: [
          {
            name: `${network.networkName} explorer`,
            url: network.explorerUrl,
            standard: 'EIP3091',
          },
        ],
        rpc: [network.rpcEndpoint],
      };
    });
    return [...CHAINS_INFO, ...networkList, ...data] as Array<IResourceChain>;
  } catch (err: unknown) {
    console.log('Failed to get chain list');
    console.log(err);
    return CHAINS_INFO;
  }
};

export function isSupportedChain(
  chainId: number | null | undefined,
): chainId is SupportedChainId {
  return !!chainId && !!SupportedChainId[chainId];
}

export const switchChain = async (chainId?: number, addTokenInfor?: IAddToken) => {
  if (window.ethereum) {
    if (!chainId) {
      throw new Error(`ChainID is required `);
    }

    try {
      await Object(window.ethereum).request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: Web3.utils.toHex(chainId) }],
      });
    } catch (err: unknown) {
      if (Object(err).code !== 4902) throw err;

      const chainList = await getChainList();
      const info = chainList.find((c: IResourceChain) => c.chainId === chainId);
      if (!info) {
        throw new Error(`Chain ${chainId} not supported`);
      }

      const params = {
        chainId: Web3.utils.toHex(info.chainId),
        chainName: info.name,
        nativeCurrency: {
          name: addTokenInfor?.symbol || info.nativeCurrency.name,
          symbol: addTokenInfor?.symbol || info.nativeCurrency.symbol,
          decimals: addTokenInfor?.decimals || info.nativeCurrency.decimals,
        },
        rpcUrls: info.rpc,
        blockExplorerUrls: [
          info.explorers && info.explorers.length > 0 && info.explorers[0].url
            ? info.explorers[0].url
            : info.infoURL,
        ],
      };

      await Object(window.ethereum).request({
        method: 'wallet_addEthereumChain',
        params: [params],
      });
    }
  }
};

interface IAddToken {
  tokenAddress: string;
  symbol: string;
  decimals: number;
  image: string;
  connector: Connector;
  currentChainID: SupportedChainId;
  requireChainID: SupportedChainId;
}

export const addToken = async (params: IAddToken) => {
  if (window.ethereum) {
    try {
      if (params.requireChainID !== params.currentChainID) {
        await switchChain(params.requireChainID, params);
      }
      await Object(params.connector).watchAsset({
        address: params.tokenAddress,
        symbol: params.symbol,
        decimals: params.decimals,
        image: params.image,
      });
    } catch (err: unknown) {
      console.log('Add token error: ', err);
      throw err;
    }
  }
};
