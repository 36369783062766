import { Flex, Text, Image } from '@chakra-ui/react';

type TokenViewProps = {
  name: string;
  iconUrl: string | undefined;
};

const NetworkView = (props: TokenViewProps) => {
  const { name, iconUrl } = props;
  return (
    <Flex flexDir={'row'} align={'center'} gap={'10px'}>
      <Image src={iconUrl} w={'32px'} h={'32px'} borderRadius={'50%'} alt={name} />
      <Text textTransform={'capitalize'}>{name}</Text>
    </Flex>
  );
};

export default NetworkView;
