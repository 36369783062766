import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  .reciever-address {
    padding-left: 20px;
    font-size: 16px;
    :disabled {
      opacity: 1;
    }
  }

  .copy-reciever-address {
    position: absolute;
    right: 6px;
  }
`;

export const WrapperContent = styled.div`
  width: 50%;

  display: flex;
  flex-direction: column;
  padding: 30px 48px 48px 48px;

  background: ${({ theme }) => theme.colors.bg1};
  border: 1px solid ${({ theme }) => theme.colors.border4};
  border-radius: 16px;
  align-self: center;

  @media screen and (max-width: 1920px) {
    width: 50%;
  }
  @media screen and (max-width: 1024px) {
    width: 80%;
    padding: 10px;
  }
  @media screen and (max-width: 768) {
    width: 100%;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 10px;

  background: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.border4};
  border-radius: 16px;
`;

export const SubmitButtonStyled = styled.button`
  margin-top: 30px;
  width: 100%;
  height: 50px;
  align-self: center;

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;
  text-align: center;
  color: #fcfcfd;

  background: ${({ theme }) => theme.colors.primary.button};
  border-radius: 100px !important;

  :disabled {
    opacity: 0.5;
    :hover {
      opacity: 0.5;
    }
  }

  :hover {
    opacity: 0.8;
  }
`;

export const SubmitButton = SubmitButtonStyled;

export const RowTokenSelect = styled.div`
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .token-select-container {
    display: flex;
    flex: 1;
  }

  .space {
    height: 100%;
    width: 20px;
  }

  .network-select-container {
    display: flex;
    flex: 1;
  }
`;

export const QrCodeAndAddressBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
