import Error from '@/componentsV2/Error';
import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { Fields } from '../FormBridge.constants';
import { getMaxErrorSelector } from '../state/selector';

const FromInputFieldError = () => {
  const form = useForm();
  const stateField = form.getFieldState(Fields.AMOUNT);
  const maxErrorMessage = useSelector(getMaxErrorSelector);
  // console.log('stateField ', stateField);
  if (!stateField) return null;

  if (maxErrorMessage) {
    return <Error message={maxErrorMessage} />;
  }

  return (
    stateField.error &&
    stateField.touched && <Error message={stateField.error} mt={'5px'} />
  );
};

export default FromInputFieldError;
