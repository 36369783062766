import { getBridgeAddress } from '@/containers/FormBridge/FormBridge.utils';
import useContractOperation from '@/hooks/contract-operations/useContractOperation';
import useGetTokenAllowance, {
  IGetTokenAllowanceParams,
} from '@/hooks/contract-operations/useGetTokenAllowance';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { isLayer2NetworkByNameSelector } from '@/state/network/selector';
import { logger } from '@/utils/logger';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { formBridgeActions } from './state/reducer';
import { getFormBridgeInfo } from './state/selector';

const enhanceAllowanceAmount = (WrappedComponent: any) => (props: any) => {
  const {
    isNativeToken,
    fromTokenSelected,
    fromNetworkSelected,
    formType,
    isSwitchNetwork,
    supportChainID,
    toNetworkSelected,
    toTokenSelected,
    isDepositForm,
  } = useSelector(getFormBridgeInfo);
  const dispatch = useAppDispatch();

  const isLayer2NetworkByNameFunc = useAppSelector(isLayer2NetworkByNameSelector);

  const { run: getTokenAllowance } = useContractOperation<
    IGetTokenAllowanceParams,
    string
  >({
    operation: useGetTokenAllowance,
    inscribeable: false,
    chainId: supportChainID,
  });

  const bridgeAddress = React.useMemo(() => {
    return getBridgeAddress({
      network: toNetworkSelected,
      token: fromTokenSelected,
    });
  }, [toNetworkSelected, fromTokenSelected]);

  const tokenAddress = React.useMemo(() => {
    return fromTokenSelected?.tcTokenID;
  }, [fromTokenSelected?.tcTokenID]);

  const loopCheckGetAllowance = React.useRef<any>();

  const onGetTokenAllowance = async () => {
    // logger('[onGetTokenAllowance] payload ', {
    //   bridgeAddress: bridgeAddress,
    //   tokenAddress: tokenAddress,
    //   isNativeToken,
    // });

    if (isNativeToken || !tokenAddress || !bridgeAddress) return;
    try {
      const result = await getTokenAllowance({
        bridgeAddress: bridgeAddress,
        tokenAddress: tokenAddress,
      });

      logger('[onGetTokenAllowance] result ', result);

      dispatch(formBridgeActions.setAllowanceAmount(result));
    } catch (error: any) {
      logger('[onGetTokenAllowance] Error ', { error });
      dispatch(formBridgeActions.setAllowanceAmount(undefined));
    }
  };

  useEffect(() => {
    if (!formType || !fromNetworkSelected || isSwitchNetwork) return;
    if (!isLayer2NetworkByNameFunc(fromNetworkSelected)) return;
    // Deposit | fromNetwork = Etherum & fromToken = ETH Native
    // => Remove GetAllowance
    if (isDepositForm) {
      clearInterval(loopCheckGetAllowance.current);
      loopCheckGetAllowance.current = undefined;
    } else {
      if (!loopCheckGetAllowance.current) {
        loopCheckGetAllowance.current = setInterval(() => {
          onGetTokenAllowance();
        }, 2000);
      }
      onGetTokenAllowance();
    }
    return () => {
      clearInterval(loopCheckGetAllowance.current);
      loopCheckGetAllowance.current = undefined;
    };
  }, [
    isNativeToken,
    fromTokenSelected,
    fromNetworkSelected,
    formType,
    toNetworkSelected,
    toTokenSelected,
    bridgeAddress,
    tokenAddress,
    isSwitchNetwork,
    isDepositForm,
  ]);

  return <WrappedComponent {...{ ...props }} />;
};
export default enhanceAllowanceAmount;
