import { memo } from 'react';
import { useSelector } from 'react-redux';
import {
  getFormBridgeInfo,
  getGenerateDepositSelector,
} from '../FormBridge/state/selector';
import Skeleton from 'react-loading-skeleton';
import { Flex, Text } from '@chakra-ui/react';

const MinimumDepositAmount = () => {
  const { isLoading, data } = useSelector(getGenerateDepositSelector);
  const { fromTokenSelected } = useSelector(getFormBridgeInfo);

  if (isLoading) return <Skeleton count={1} />;
  if (!data) return null;

  const { minDepositAmountFormatedStr, minDepositShouldBeHide } = data;

  if (!minDepositAmountFormatedStr || minDepositShouldBeHide) return null;

  return (
    <Flex align={'center'} justify={'space-between'}>
      <Text>{'Minimum deposit amount'}</Text>
      <Text
        fontWeight={600}
      >{`${minDepositAmountFormatedStr} ${fromTokenSelected?.symbol?.toUpperCase()}`}</Text>
    </Flex>
  );
};

export default memo(MinimumDepositAmount);
