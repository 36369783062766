import toast from 'react-hot-toast';
import { getFormBridgeInfo } from './state/selector';
import { useSelector } from 'react-redux';
import { parseError } from '@/utils/errorHelper';

const enhanceSubmit = (WrappedComponent: any) => (props: any) => {
  const { formType } = useSelector(getFormBridgeInfo);
  const { onWithdrawHandler, onDepositHandler, onTransferHandler } = props;
  const submitHandler = async (data: any) => {
    console.log('[submitHandler] data ', data);
    try {
      if (!data) return;
      if (formType === 'Deposit') {
        onDepositHandler(data);
      } else if (formType === 'Withdraw') {
        onWithdrawHandler(data, true);
      } else if (formType === 'Transfer') {
        onTransferHandler(data, true);
        //TO DO
      } else if (formType === 'CrossLayer2') {
        //TO DO
      }
    } catch (error) {
      console.log('[submitHandler] ERROR: ', error);
      toast.error(parseError(error));
    }
  };

  return <WrappedComponent {...{ ...props, submitHandler }} />;
};
export default enhanceSubmit;
