import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { getFeeBurnNativeTokenSelector, getFormBridgeInfo } from '../state/selector';
import { Flex, Text } from '@chakra-ui/react';

const GasPriceEst = () => {
  const { isLoading, tokenSymbol, isCorrectChain, isNativeToken, isTransferForm } =
    useSelector(getFormBridgeInfo);
  const { feeBurnNativeTokenFormated_Str } = useSelector(
    getFeeBurnNativeTokenSelector,
  );
  if (isLoading) return <Skeleton count={1} />;
  if (!isCorrectChain) return null;
  if (isTransferForm) return null;

  if (isNativeToken)
    return (
      <Flex align={'center'} justify={'space-between'}>
        <Text>{'Gas (est.)'}</Text>
        <Text
          fontWeight={600}
        >{`${feeBurnNativeTokenFormated_Str} ${tokenSymbol}`}</Text>
      </Flex>
    );
  return null;
};

export default GasPriceEst;
