import { isLayer2 } from '@/constants/network';
import useGetBalance from '@/hooks/useGetBalance';
import { useAppDispatch } from '@/state/hooks';
import { useWeb3React } from '@web3-react/core';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { formBridgeActions } from './state/reducer';
import { getFormBridgeInfo } from './state/selector';

const LOAD_BALANCE_INTERVAL_TIMER = 12000; //Other (Etherum | L1 ....)
const LOAD_BALANCE_INTERVAL_TIMER_L2 = 6000; //6s for L2, L2s, Naka ...

const enhanceBalance = (WrappedComponent: any) => (props: any) => {
  const { getNativeTokenBalance, getNormalTokenBalance } = useGetBalance();

  const dispatch = useAppDispatch();
  const web3Data = useWeb3React();

  const timerRef = React.useRef<any>();

  const {
    isNativeToken,
    fromTokenSelected,
    toTokenSelected,
    fromNetworkSelected,
    toNetworkSelected,
    isCorrectChain,
    formType,
  } = useSelector(getFormBridgeInfo);

  const isGetBalanceable = useMemo(() => {
    let flag = true;
    if (formType === 'Deposit') {
      // Deposit Centralize => Don't Get Balance
      flag = false;
    } else if (!isCorrectChain) {
      // fromNetwork != Current network on Metmask => Don't Get Balance
      flag = false;
    } else if (!fromTokenSelected || !fromTokenSelected.tokenID) {
      flag = false;
    }
    return flag;
  }, [
    isNativeToken,
    fromTokenSelected,
    toTokenSelected,
    fromNetworkSelected,
    toNetworkSelected,
    web3Data,
    isCorrectChain,
  ]);

  const INTERVAL_TIME = isLayer2(fromNetworkSelected)
    ? LOAD_BALANCE_INTERVAL_TIMER_L2
    : LOAD_BALANCE_INTERVAL_TIMER;

  const clearTimer = () => {
    clearInterval(timerRef.current);
    timerRef.current = undefined;
  };

  const loadBalanceHandler = async () => {
    let balance = '0';
    try {
      if (isNativeToken) {
        balance = await getNativeTokenBalance();
      } else {
        balance = await getNormalTokenBalance({
          tokenAddress: fromTokenSelected?.tokenID!,
        });
      }
    } catch (error) {
      console.log('[loadBalanceHandler] error ', error);
      balance = '0';
    } finally {
      console.log('[loadBalanceHandler] BALANCE --  ', {
        fromTokenSelected,
        balance,
        fromNetworkSelected,
      });
      dispatch(formBridgeActions.setFromBalance(balance));
    }
  };

  const loadBalanceDebouncing = useCallback(debounce(loadBalanceHandler, 300), [
    fromTokenSelected,
    isGetBalanceable,
  ]);

  useEffect(() => {
    clearTimer();
    if (isGetBalanceable) {
      loadBalanceDebouncing();
      timerRef.current = setInterval(() => {
        loadBalanceDebouncing();
      }, INTERVAL_TIME);
    }
    return () => {
      clearTimer();
    };
  }, [isGetBalanceable, fromTokenSelected]);

  return <WrappedComponent {...{ ...props }} />;
};
export default enhanceBalance;
