import { Token } from '@/state/tokens/types';
import localStorage from '@/utils/localstorage';
import { Keys } from '@/containers/Withdraw/Withdraw.localStorage';

// key = [tcAddress]-[tokenAddress]
// value = approve-tx-hash

type ApproveTxsDataType = {
  approveTxHash: string;
  amountInput?: string;
  time: number;
  allowanceAmount: string;
  tokenInfo?: Token;
};

type ApproveTxsMapType = {
  [key: string]: ApproveTxsDataType;
};

type SetApproveTxHashType = {
  approveTxHash: string;
  amountInput?: string;
  keyDefined: string;
  allowanceAmount: string;
  tokenInfo?: Token;
};

type GetKeyDefineByData = {
  tcAddress: string;
  tokenAddress: string;
  bridgeAddress: string;
};

const KEYS = {
  APPROVE_TXS: 'APPROVE_TXS',
};

export const saveApproveTxHash = (param: SetApproveTxHashType) => {
  const { approveTxHash, keyDefined, amountInput, allowanceAmount, tokenInfo } =
    param;

  // validate
  if (!approveTxHash || !keyDefined) return;

  // save data
  const data: ApproveTxsDataType = {
    approveTxHash,
    amountInput: amountInput,
    time: new Date().getTime(),
    allowanceAmount,
    tokenInfo,
  };

  // update
  approveTxsMap[keyDefined] = data;

  // save local
  localStorage.set(KEYS.APPROVE_TXS, approveTxsMap);
};

export const getApproveTxDataByKey = (
  keyDefined: string,
): ApproveTxsDataType | undefined => {
  if (!keyDefined) undefined;
  // Get approve tx hash
  return approveTxsMap[keyDefined];
};

export const getKeyDefineByData = (param: GetKeyDefineByData): string => {
  const { tcAddress, tokenAddress, bridgeAddress } = param;
  // Define Key
  const key = `[${tcAddress}]-[${tokenAddress}]-[${bridgeAddress}]`;
  return key;
};

export const approveTxsMap: ApproveTxsMapType =
  localStorage.get(KEYS.APPROVE_TXS) || {}; // Dict

export const removeApproveTxsMap = (key: string) => {
  const txs = { ...approveTxsMap };
  delete txs[key];
  localStorage.set(KEYS.APPROVE_TXS, txs || {}); // Dict
};

export const cellarAllApproveTxsMap = async () => {
  localStorage.set(KEYS.APPROVE_TXS, {}); // Dict
};
