import React from 'react';
import * as S from './styled';
import Text from '@components/Text';
import { Modal, Spinner } from 'react-bootstrap';
import { CDN_URL } from '@/configs';
import AppButton from '@/componentsV2/Button';

interface IProps {
  symbol: string;
  show: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

const ApproveModal = React.memo((props: IProps) => {
  const { symbol, show, onSubmit, onClose } = props;

  const [loading, setLoading] = React.useState(false);

  const _onSubmit = async () => {
    setLoading(true);
    await onSubmit();
    setLoading(false);
  };

  return (
    <S.Container show={show} centered keyboard={false} onHide={onClose}>
      <Modal.Header closeButton color="white" closeVariant="white">
        <Modal.Title>APPROVE USE OF {symbol}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <S.Body>
          <Text fontSize={18}>
            When the Metamask pop-up appears, follow this guide and click the default
            option to save money on gas fees for your next transaction.
          </Text>
          <S.Image src={`${CDN_URL}/images/guide_approve_amount.png`} />
          <AppButton onClick={_onSubmit} disabled={loading}>
            {loading ? (
              <Spinner animation="border" role="status" style={{ color: 'white' }} />
            ) : (
              'CONFIRM'
            )}
          </AppButton>
        </S.Body>
      </Modal.Body>
    </S.Container>
  );
});

export default ApproveModal;
