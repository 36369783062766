import { memo } from 'react';
import { useSelector } from 'react-redux';
import { getEstimateTimeProcess } from '../state/selector';
import { Flex, Text } from '@chakra-ui/react';

const WithdrawFeeEstimate = () => {
  const data = useSelector(getEstimateTimeProcess);

  if (!data || !data.estTime) return null;

  return (
    <Flex flexDir={'row'} align={'center'} justify={'space-between'}>
      <Text>{'Transfer time'}</Text>
      <Text fontWeight={600}>{`~ ${data.estTimeStr}`}</Text>
    </Flex>
  );
};

export default memo(WithdrawFeeEstimate);
