import { Box, Row, RowTextInput } from './FormBridge.styles';

import FromBalanceBox from './components/FromBalanceBox';
import FromInputField from './components/FromInputField';
import FromInputFieldError from './components/FromInputFieldError';

import ToInputField from './components/ToInputField';
import ToInputFieldError from './components/ToInputFieldError';

import Space from '@components/Space';
import { useSelector } from 'react-redux';
import Divider from './components/Divider';
import EstimateTimeBox from './components/EstimateTimeBox';
import GasPriceEst from './components/GasPriceEst';
import MaxButton from './components/MaxButton';
import MinimumWithdrawAmount from './components/MinimumWithdrawAmount';
import SwitchChainWarning from './components/SwitchChainWarning';
import WithdrawFeeEstimate from './components/WithdrawFeeEstimate';
import { toNetworkFactorySelectorV2 } from './state/selector';
import { Flex, Text } from '@chakra-ui/react';

const WithdrawInput = (props: any) => {
  const { switchChainHandler, maxHandler } = props;

  const { disbaled } = useSelector(toNetworkFactorySelectorV2);

  if (disbaled) return null;

  return (
    <>
      <Row>
        <Text fontWeight={600}>{'Total amount'}</Text>
        <FromBalanceBox />
      </Row>

      <Space.Vertical size={8} />

      <Box style={{ gap: 8 }}>
        <RowTextInput>
          <FromInputField />
          <MaxButton onClick={maxHandler} />
        </RowTextInput>
      </Box>
      <FromInputFieldError />

      <Space.Vertical size={4} />

      <Box style={{ gap: 8 }}>
        <ToInputField />
        <ToInputFieldError />
      </Box>

      <Space.Vertical size={16} />
      <Divider />
      <Space.Vertical size={16} />

      {/* <WarningNeedDepositBTCMore /> */}
      {/* <YourBTCBalance /> */}

      <Flex flexDir={'column'} gap={'6px'}>
        <WithdrawFeeEstimate />
        <MinimumWithdrawAmount />
        <GasPriceEst />
        <EstimateTimeBox />
        <SwitchChainWarning switchChainOnClick={switchChainHandler} />
      </Flex>
    </>
  );
};

export default WithdrawInput;
