import AppInput from '@/componentsV2/Input';
import validator, { composeValidators } from '@/utils/validator';
import { Flex, Text } from '@chakra-ui/react';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import { Fields } from './FormBridge.constants';
import { getFormBridgeInfo } from './state/selector';

const DepositInput = () => {
  const { toInputPlaceHolder } = useSelector(getFormBridgeInfo);
  return (
    <Field
      name={Fields.ADDRESS}
      validate={composeValidators(
        validator.addressRequired(),
        validator.validateEVMAddress(),
      )}
    >
      {({ input, meta }) => (
        <Flex flexDir={'column'} gap={'8px'}>
          <AppInput {...input} {...meta} placeholder={`${toInputPlaceHolder}`} />
          {meta.error && meta.touched && (
            <Text fontSize={'16px'} color={'#ff5747'}>
              {meta.error}
            </Text>
          )}
        </Flex>
      )}
    </Field>
  );
};

export default DepositInput;
