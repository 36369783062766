import { useForm } from 'react-final-form';
import { Fields } from '../FormBridge.constants';
import Error from '@/componentsV2/Error';

const ToInputFieldError = () => {
  const form = useForm();
  const stateField = form.getFieldState(Fields.ADDRESS);
  if (!stateField) return null;

  return (
    stateField.error &&
    stateField.touched && <Error message={stateField.error} mt={'5px'} />
  );
};

export default ToInputFieldError;
