import { compose } from '@reduxjs/toolkit';
import { FC } from 'react';

import withFetcher from '@/hocs/withFetcher';
import filterNetworkNameAvailableList from '@/hocs/filterNetworkNameAvailableList';
import withDefaultFromNetwork from '@/hocs/withDefaultFromNetwork';

const enhance = (WrappedComponent: FC) => (props: any) => {
  return <WrappedComponent {...props} />;
};

export default compose<FC>(
  withFetcher,
  filterNetworkNameAvailableList,
  withDefaultFromNetwork,
  enhance,
);
