import { Button, ButtonProps } from '@chakra-ui/react';

interface AppButtonProps extends ButtonProps {}

const AppButton = (props: AppButtonProps) => {
  return (
    <Button
      minH={'50px'}
      fontSize={'16px'}
      fontWeight={600}
      textAlign={'center'}
      bgColor={'#3772ff'}
      color={'#fff'}
      borderRadius={'12px'}
      _hover={{
        opacity: 0.7,
        cursor: 'pointer',
      }}
      _disabled={{
        opacity: 1,
        cursor: 'not-allowed',
      }}
      {...props}
    >
      {`${props.title || ''}`}
    </Button>
  );
};

export default AppButton;
